import React, { useEffect, useState } from 'react';
import { Text, View, Image, StyleSheet, Linking, ActivityIndicator, Platform, TextInput} from 'react-native';
import Modal from 'react-native-modal';
import moment from 'moment-timezone';
import Dropdown from '../components/Dropdown';
import Switch from '../components/forms/Switch';
let NativeModules;
let NativeEventEmitter;

if (Platform.OS !== 'web') {
  NativeModules = require('react-native').NativeModules;
  NativeEventEmitter = require('react-native').NativeEventEmitter;
}
//import { Dropdown } from 'react-native-material-dropdown';
//import RNPickerSelect from "@react-native-community/picker";

import Screen from '../components/Screen';
import alert from '../components/alert';
import Button from '../components/Button';
import routes from "../navigation/routes";
import useAuth from "../auth/useAuth";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import secureStorage from "../auth/vpnSecureStorage";
import { ErrorMessage, ErrorMessage2 } from "../components/forms";
import colors from '../config/colors';
import { setEnabled } from 'react-native/Libraries/Performance/Systrace';
//import { createNativeWrapper } from 'react-native-gesture-handler';


function DeviceScreen({ route, navigation }){
    const auth = useAuth();
    const [keepConnectsArray, setKeepConnectsArray] = useState([{label: "No Keep Connects Registered", value: "null"}]);
    const [incompatibleKeepConnects, setIncompatibleKeepConnects] = useState([]);
    const [boolIncompatibleKeepConnects, setBoolIncompatibleKeepConnects] = useState(false);
    const [isSubscribed, setSubscription] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [selectedValue, setSelectedValue]= useState("");
    const [selectedName, setSelectedName]= useState();
    const getKeepConnectSettingsApi = useApi(keepConnectApi.getKeepConnectSettings);
    const triggerResetKeepConnectApi = useApi(keepConnectApi.triggersKeepConnectApi);
    const removeKeepConnectApi = useApi(keepConnectApi.removeKeepConnect); 
    const [defaultKeepConnect, setDefaultKeepConnect] = useState("");
    const [visibleVPN, setVisibleVPN] = useState(false);
    const [selectedTunnelType, setSelectedTunnelType] = useState(0);
    const [selectedConnType, setSelectedConnType] = useState(0);
    const [storeVPN, setStoreVPN] = useState(true);
    const [vpnPassword, setVPNpassword] = useState("");
    const [vpnPort, setVPNport] = useState("4550");
    const [visibleTrigger, setVisibleTrigger] = useState(false);
    const [visibleTrigger2, setVisibleTrigger2] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("Error Communicating with Server");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [emptyKeepConnects, setEmptyKeepConnects] = useState(true);
    const [updateNeeded, setUpdateNeeded] = useState(false);
    const [updateNeededHigherLevel, setUpdateNeededHigherLevel] = useState(false);
    const [data, setSettings] = useState();
    const [newSettings1, setNewSettings1] = useState(false); //used for adding new settings with KeepConnectV2
    const [enablePowerPal, setPowerPal] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [appTimezone, setAppTimeZone] = useState("America/New_York");
    const [enterprise, setEnterprise] = useState(false);
    const [vpnEnabled, setVPNenabled] = useState(false);
    const [vpnConnectionType, setVpnConnectionType] = useState("");
    const [thisIsVPN, setThisIsVPN] = useState(false);
    const VPN_STATES = {
        Disconnected: 0,
        Connecting: 1,
        Connected: 2,
        Disconnecting: 3,
        Reconnecting: 4,
      };
    const [vpnState, setVPNState] = useState(VPN_STATES.disconnected);

    const [vpnSettings, setVpnSettings] = useState({
        vpnPassword: '',
        selectedTunnelType: 0,
        selectedConnType: 0,
        vpnPort: '4550',
        vpnRoute: '',
        vpnRouteSubnet: '',
        storeVPN: true,
        init: false,
      });


      useEffect(() => {
        loadKeepConnects();
        if(Platform.OS !== 'web'){
                const { KCVPNInterface } = NativeModules;
                const handleStatusChange = (response) => {
                    //console.log('VPN status did change:', response.status, "MAC Address:", response.mac);
                    if(response.mac == route.params.item.MAC) {
                        setThisIsVPN(true);
                    } else{
                        setThisIsVPN(false);
                    }
                    const state = VPN_STATES[response.status];
                    if (state !== undefined) {
                    setVPNState(state);
                    } else {
                    //console.log('Unknown status:', response.status);
                    }
                };
            
                KCVPNInterface.getVPNStatus((response) => {
                    //console.log("VPN found status:", response.status, "MAC Address:", response.mac);
                    if(response.mac == route.params.item.MAC) {
                        setThisIsVPN(true);
                    } else{
                        setThisIsVPN(false);
                    }
                    const state = VPN_STATES[response.status];
                    if (state !== undefined) {
                    setVPNState(state);
                    } else {
                    //console.log('Unknown status:', response.status);
                    }
                });
            
                const eventEmitter = new NativeEventEmitter(KCVPNInterface);
            
                // Add the listener and keep a reference to the subscription
                const subscription = eventEmitter.addListener('vpnStatusDidChange', handleStatusChange);
            
                // Cleanup function:
                return () => {
                    // Use the remove method on the subscription object
                    subscription.remove();
                };
        }
        
    }, []);

    
    useEffect(() => {
        if(Platform.OS !== 'web'){
                const { KCVPNInterface } = NativeModules;
                if (thisIsVPN && vpnState === VPN_STATES.Connected) {
                    if (typeof KCVPNInterface.getVPNConnectionType === 'function') { //check if this exists, previous release didn't include this
                        KCVPNInterface.getVPNConnectionType((connectionType) => {
                            //console.log("connectionType=", connectionType);
                            setVpnConnectionType(connectionType);
                        });
                    } else{
                        setVpnConnectionType("Relay"); //if getVPNConnectionType doesn't exist, then that's before the release where Direct was allowed anyway.
                    }
                } else {
                setVpnConnectionType(""); // reset the value when VPN is not connected
                }
        }
      }, [thisIsVPN, vpnState]);
      

    const loadKeepConnects = async () => {
        //setIsloading(true);
        //setIsLoading(false);
        //console.log(await keepConnectStore.get("keepConnect"));
        //console.log(route.params.item.MAC);
        keepConnectStore.store("keepConnect", (route.params.item.MAC));
        const response = await getKeepConnectSettingsApi.request("\"" + route.params.item.MAC +"\"");
        const settings = await secureStorage.getSettings(route.params.item.MAC);
        if(settings){
            setVpnSettings(settings); //if vpn settings are stored, initialize them
        }
        setSettings(response.data);
        //console.log(response.data[0]);
        //console.log(data[0]);
        setVPNenabled(response.data[0].firmware.includes("VPN"));

        const firmwareString = response.data[0].firmware;
        const majorVersionStart = firmwareString.indexOf("_V") + 2;
        const majorVersionEnd = firmwareString.indexOf("_", majorVersionStart);
        const majorVersion = parseInt(firmwareString.substring(majorVersionStart, majorVersionEnd), 10);
        setNewSettings1(majorVersion >= 3);

        setPowerPal(response.data[0].firmware.includes("PowerPal"));
        var then = moment(response.data[0].lastConnectTime, "YYYY-DD-MM HH:mm:ss");
        var now = moment().tz("America/New_York");
        setIsConnected(now.diff(then, 'minutes') <= 7);

        const storedTimezone = await keepConnectStore.get("kc_appTimezone");
        const correctedStoredTimezone = (storedTimezone !== null ? storedTimezone.replace(/\"/g, "") : "America/New_York");
        setAppTimeZone(correctedStoredTimezone);
        const storedLicense = await keepConnectStore.get("license");
        const correctedStoredLicense = (storedLicense !== null ? storedLicense.replace(/\"/g, "") : "basic");
        setEnterprise(correctedStoredLicense == "enterprise");
        checkAndSetFirmwareCompatibility();
        setIsLoading(false);
        setRefreshing(false);
        }

    if (updateNeeded || updateNeededHigherLevel){
        if(Platform.OS === 'web') setIsLoading(true);
        setRefreshing(true);
        setUpdateNeeded(false);
        if(updateNeededHigherLevel) route.params.changedDevices(true);
        setUpdateNeededHigherLevel(false);
        loadKeepConnects();
    }

    const convertTimezone = (item) =>{
        moment.tz.setDefault("America/New_York");
        var timestamp = moment(item, "YYYY-DD-MM HH:mm:ss");
        return timestamp.tz(appTimezone).format('YYYY-MM-DD HH:mm:ss');
    }

    const checkAndSetFirmwareCompatibility = () => {
        if (route.params.item.firmware.includes("KeepConnect") || route.params.item.firmware.includes("PowerPal")) setBoolIncompatibleKeepConnects(false);
        else setBoolIncompatibleKeepConnects(true);
    }

    const handleRequestFirmwareUpdate = async () => {
        const keepConnectCodeForEmail = route.params.item.WebID;
        
        alert(
            "Firmware Update",
            "Our Support Staff will stage your device to receive the update. This will cause your device to reboot and will power cycle your router/modem as well. The update will happen within appx 1hr once our staff has staged your device for upgrade. If you require a specific time for your device to be updated, please note this in the email generated by clicking the \"Proceed\" Button.",
            [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel"
                },
                { text: "Proceed", onPress: () => {
                    Linking.openURL('mailto: support@johnson-creative.com?subject=Firmware Update Request&body=Please Update Keep Connect Device with WebID ' + keepConnectCodeForEmail);
                } }
              ]
          );
    }

    const handleLearnMoreFirmwareUpdate = ()=>{
        alert(
            "Firmware Update Needed",
            "The selected device requires newer firmware for full compatibility with Keep Connect Cloud Services. The \"Trigger Reset\" and \"Change Settings\" features will not work until the firmware is updated. All other features will work as expected without updating the firmware. Click the \"Request Firmware Update\" option to generate firmware update request email.",
            [
              { text: "OK", onPress: () => {} }
            ]
          );
    }

    /*
    const bwjCrossPlatformAlert = (title, body, buttons)=>{
        console.log("crossPlat");
        if(Platform !== 'web'){
            Alert.alert(title, body, buttons);
        } else{

        }
    }
    */

    const toggleModal = () => {
        setVisibleTrigger(!visibleTrigger);
        };

    const handleTrigger = async (sendIt) => {
        setVisibleTrigger(false);
        if(sendIt){
        const keepConnectCode = route.params.item.MAC;
        const response = await triggerResetKeepConnectApi.request({keepConnects: keepConnectCode.replace(/\"/g, ""), triggerType: "remoteReboot"});
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
            "Success!",
            "Reboot was staged successfully. Your device will read and apply this trigger in appx 5min.",
            [
              { text: "OK", onPress: () => {} }
            ]
          );
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        }
        }

    function getNetworkAddress(ip, subnet) {
        let ipArray = ip.split(".");
        let subnetArray = subnet.split(".");
        let networkAddressArray = [];
        
        for(let i = 0; i < 4; i++) {
            networkAddressArray[i] = Number(ipArray[i]) & Number(subnetArray[i]);
        }
        
        return networkAddressArray.join(".");
    }


    const handleVPNsave = async (proceed) => {
        setVisibleVPN(false);
        if(!proceed) return;
        /*
        console.log(vpnSettings.selectedConnType == 0 ? "vpnserver.johnson-creative.com" : "test");
        console.log(vpnSettings.selectedConnType == 0 ? "4550" : vpnSettings.vpnPort);
        console.log(data[0].macAddress);
        console.log(vpnSettings.vpnPassword);
        console.log(getNetworkAddress(data[0].localIP, data[0].localSubnet));
        console.log(data[0].localSubnet);
        console.log(vpnSettings.selectedConnType == 1);
        */
        vpnSettings.init = true;
        if(vpnSettings.storeVPN){ //if allowed, store settings in secure storage
            await secureStorage.storeSettings(route.params.item.MAC, vpnSettings);
        } else{
            await secureStorage.removeSettings(route.params.item.MAC);
        }
    }

    const handleDeregister = async (sendIt) => {
        setVisibleTrigger2(false);
        if(sendIt){
        setIsLoading(true);
        const keepConnectCode = route.params.item.MAC;
        const response = await removeKeepConnectApi.request(keepConnectCode.replace(/\"/g, ""));
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Your Keep Connect was successfully removed!",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        //loadKeepConnects();
        route.params.changedDevices(true);
        navigation.goBack();
        }
        }

        const displayVPNNotice = () => {
            var msg = "";
            if (!enterprise){
                msg = "Pro Or Enterprise License Is Required.";
            } else if(!vpnEnabled){
                msg = "Device requires firmware update.";
            } else if(data[0].vpnMode == "0"){
                msg = "Device VPN setting is set to off. Send new settings to the device and refresh this page to clear this error.";
            }
            alert(
                "Error",
                msg,
                [
                  { text: "OK", onPress: () => {} }
                ]
              );
        }

    return(
        <Screen isLoading={refreshing} setUpdateNeeded={setUpdateNeeded}>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <React.Fragment>
        <View style={{padding: 20, borderTopWidth :3, borderTopColor: colors.primary, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <View style={styles.deviceBox}>
        <ErrorMessage
        error={requestMessage}
        visible={requestFailed}
        />
        <ErrorMessage
        error={serverMessage}
        visible={errorFromServer}
        />
        <ErrorMessage2
          error={"Firmware Update Required\nFor Selected Keep Connect"}
          visible={boolIncompatibleKeepConnects}
        />
        {boolIncompatibleKeepConnects ? <Text style={styles.TextStyle} onPress={ ()=> handleLearnMoreFirmwareUpdate() } >Learn More</Text> : null}
        {boolIncompatibleKeepConnects ? <><Text style={styles.TextStyle} onPress={ ()=> handleRequestFirmwareUpdate() } >Request Firmware Update</Text><Text></Text></> : null}
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Keep Connect Name: {route.params.item.Name}</Text>
        <Text style={{alignSelf: "center"}}>{route.params.id}</Text><Text></Text>
        {Platform.OS === 'web' && <Text> </Text>}
        <View style={{alignItems: "center"}}>{isConnected ? <View style={{width:200, backgroundColor: '#33cc33', borderRadius: 30, justifyContent: 'center'}}>
        <Text style={{margin: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}> Online and Connected </Text>
        </View> : 
        <View style={{width:200, backgroundColor: 'red', borderRadius: 30, alignContent: 'center'}}>
        <Text style={{margin: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}> Offline </Text>
        </View>}</View>
        {Platform.OS === 'web' && <Text> </Text>}
        <Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Web ID: </Text><Text>{data[0].id}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>IP: </Text><Text>{data[0].IPaddress}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Mac: </Text><Text>{data[0].macAddress}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Last Connected: </Text><Text>{} </Text><Text>{convertTimezone(data[0].lastConnectTime)} ({moment().tz(appTimezone).format('z')})</Text></Text>
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Last Reset: </Text><Text>{convertTimezone(data[0].lastResetTime)} ({moment().tz(appTimezone).format('z')})</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Number of Resets: </Text><Text>{data[0].numberOfResets}</Text></Text>}
        {enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Number of Events: </Text><Text>{data[0].numberOfResets}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Cloud Notifications: </Text><Text>{data[0].offlineCloudNotificationDelay == 0 ? "Not Enabled" : "Enabled"}</Text></Text>}
        <Text>{'\n'}</Text>
        <Image
            source={enablePowerPal ? require('../assets/ppIcon.png') : require('../assets/kcIcon.png')} // Adjust the path to your image file
            style={styles.imageStyle}
        />
        </View>
        {Platform.OS === 'ios' ? <></> : <Text style={styles.TextStyle} onPress={ ()=> setUpdateNeeded(true) } >Refresh</Text>}
        {!enablePowerPal && <Button
            title="Trigger Reset"
            onPress={toggleModal}
        />}
        {!enablePowerPal && <Button
            title="View/Change Settings"
            onPress={() => navigation.navigate(routes.CHANGESETTINGS, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
        />}
        {enablePowerPal && <Button
            title="View/Change Settings"
            onPress={() => navigation.navigate(routes.CHANGESETTINGSPOWERPAL, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
        />}
        <Button
            title="View Event History"
            onPress={() => navigation.navigate(routes.DASHBOARD, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
        />
        {!enablePowerPal && <Button
            title="Notification Settings"
            onPress={() => navigation.navigate(routes.MESSAGESETTINGS, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
        />}
        <Button
            title="Deregister Device"
            onPress={() => setVisibleTrigger2(true)}
        />
        <Button
            title="More Device Details"
            onPress={() => navigation.navigate(routes.PRESENTSETTINGS, { item: route.params.item})}
        />
        {Platform.OS !== 'web' && <View style={styles.deviceBox}>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>VPN Control</Text>
        {thisIsVPN && vpnState === VPN_STATES.Connecting ? <Text style={{alignSelf: "center"}}>Initiating Connection...</Text> : <></>}
        {thisIsVPN && vpnState === VPN_STATES.Disconnecting ? <Text style={{alignSelf: "center"}}>Disconnecting...</Text> : <></>}
        <View style={{paddingTop: 2, flexDirection: "row"}}>
        <View style={{flex: 1, marginRight: 5}}>
        <Button
            title="Start"
            onPress={() => {
                //NativeModules.KCVPNInterface.setVPNParams("vpnserver.johnson-creative.com", "4550", "60:55:F9:25:D8:44", "byron", "192.168.2.0", "255.255.255.0", false);
                if(!vpnEnabled || !enterprise || data[0].vpnMode == "0") {displayVPNNotice(); return;}
                if(vpnSettings.init){
                    NativeModules.KCVPNInterface.stopVPN((success) =>{
                    NativeModules.KCVPNInterface.setVPNParams(
                        vpnSettings.selectedConnType == 0 ? "vpnserver.johnson-creative.com" : data[0].IPaddress, //Packetforwarder OR direct IP
                        vpnSettings.selectedConnType == 0 ? "4550" : vpnSettings.vpnPort, //Packetforwarder(4550) OR custom direct port
                        data[0].macAddress, //macAddress
                        vpnSettings.vpnPassword, //password
                        vpnSettings.selectedTunnelType == 1 ? "0.0.0.0" : getNetworkAddress(data[0].localIP, data[0].localSubnet), //vpm route = get network address
                        vpnSettings.selectedTunnelType == 1 ? "0.0.0.0" : data[0].localSubnet, //route subnet
                        vpnSettings.selectedConnType == 1 //if ==1 then do "direct connect"
                      );
                      setThisIsVPN(true);
                        /*  
                        console.log("Packetforwarder OR direct IP:", vpnSettings.selectedConnType == 0 ? "vpnserver.johnson-creative.com" : data[0].IPaddress);
                        console.log("Packetforwarder(4550) OR custom direct port:", vpnSettings.selectedConnType == 0 ? "4550" : vpnSettings.vpnPort);
                        console.log("macAddress:", data[0].macAddress);
                        console.log("password:", vpnSettings.vpnPassword);
                        console.log("VPN route = get network address:", vpnSettings.selectedTunnelType == 1 ? "0.0.0.0" : getNetworkAddress(data[0].localIP, data[0].localSubnet));
                        console.log("Route subnet:", vpnSettings.selectedTunnelType == 1 ? "0.0.0.0" : data[0].localSubnet);
                        console.log("Direct connect (selectedConnType == 1):", vpnSettings.selectedConnType == 1);
                        */
                      NativeModules.KCVPNInterface.startVPN((success) => {
                          //console.log("VPN startup success:", success);
                          if(!success){
                            alert(
                                "VPN Config Failed",
                                "The selected device failed to install the VPN profile.",
                                [
                                  { text: "OK", onPress: () => {} }
                                ]
                              );
                          }
                        });
                    })
                    } else{
                        setVisibleVPN(true);
                    }
            }}
        />
        </View>
        <View style={{flex: 1, marginRight: 5}}>
        <Button
            title="Stop"
            onPress={() => {
                if(!vpnEnabled || !enterprise) {displayVPNNotice(); return;}
                NativeModules.KCVPNInterface.stopVPN(()=>{});
            }
            }
        />
        </View>
        </View>
        <View style={{paddingTop: 2, flexDirection: "row", alignItems: 'center'}}>
        <View style={{flex: 1, marginRight: 5}}>
        <Button
            title="Set"
            onPress={() => {
                if(!vpnEnabled || !enterprise) {displayVPNNotice(); return;}
                setVisibleVPN(true);
            }}
        />
        </View>
        <View style={{flex: 1, marginRight: 5, marginLeft: 25}}>
            {thisIsVPN && vpnState === VPN_STATES.Connected ? <View style={{width:100, backgroundColor: '#33cc33', borderRadius: 30, justifyContent: 'center'}}>
            <Text style={{marginTop: 5, marginBottom: 0, marginLeft: 10, marginRight: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}>Connected</Text>
            <Text style={{marginTop: 0, marginBottom: 5, marginLeft: 10, marginRight: 10, color: "white", alignSelf: "center"}}>({vpnConnectionType})</Text>
            </View> : 
            <View style={{width:100, backgroundColor: 'gray', borderRadius: 30, alignContent: 'center'}}>
            <Text style={{margin: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}> Off </Text>
            </View>}
        </View>
        </View>
        </View>}
        <Modal isVisible={visibleVPN} transparent={true} animationType = {"slide"} onBackdropPress={()=>handleVPNsave(false)}>
        <View style={{height: 700,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{fontWeight: "bold", alignSelf: "center"}}>Enter VPN Settings {'\n'}</Text>
            <Text style={{alignSelf: "center"}}>Enter Device Password {'\n'}</Text>
            <TextInput
                secureTextEntry={true}
                style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
                placeholder="Enter your password"
                onChangeText={text => setVpnSettings(prevState => ({ ...prevState, vpnPassword: text }))}
                value={vpnSettings.vpnPassword}
            />
            <Text style={{alignSelf: "center"}}>Note: This is the password entered into the device during setup. It remains local to the device and is not related to your Cloud Services account password. {'\n'}</Text>
            <Dropdown
                onTop={true}
                labelText='Tunnel Type'
                defaultValueProp={vpnSettings.selectedTunnelType}
                itemsArray= {[
                    {label: 'LAN Traffic Only', value: 0},
                    {label: 'All Traffic', value: 1 },
                ]}
                onChangeItemProp={item => setVpnSettings(prevState => ({ ...prevState, selectedTunnelType: item }))}
            />
            <Dropdown
                labelText='Connection Method'
                defaultValueProp={vpnSettings.selectedConnType}
                itemsArray= {[
                    {label: 'Auto (Relay)', value: 0},
                    {label: 'Direct (Port Forward)', value: 1 },
                ]}
                onChangeItemProp={item => setVpnSettings(prevState => ({ ...prevState, selectedConnType: item }))}
            />
            {vpnSettings.selectedConnType === 1 && <TextInput
                keyboardType="numeric"
                style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
                placeholder="Enter Device Port"
                onChangeText={text => setVpnSettings(prevState => ({ ...prevState, vpnPort: text }))}
                value={vpnSettings.vpnPort}
            />}
            <Switch 
                labelText='Store VPN Settings'
                switchState={vpnSettings.storeVPN}
                setSwitchState={(value) => setVpnSettings(prevState => ({ ...prevState, storeVPN: value }))}
                isDisabled = {false}
            />
            <Button title="Save" onPress={()=>handleVPNsave(true)} />
            <Button title="Cancel" onPress={()=>handleVPNsave(false)} />
            </View>
        </View>
        </Modal>
        <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"} onBackdropPress={()=>handleTrigger(false)}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Are you sure you want to send a reset to:{'\n'}{route.params.item.Name}</Text>
            <Button title="Send It!" onPress={()=>handleTrigger(true)} />
            <Button title="Cancel" onPress={()=>handleTrigger(false)} />
            </View>
            </View>
        </Modal>
        <Modal isVisible={visibleTrigger2} transparent={true} animationType = {"slide"} onBackdropPress={()=>handleDeregister(false)}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Are you sure you want to deregister:{'\n'}{route.params.item.Name}</Text>
            <Button title="Proceed" onPress={()=>handleDeregister(true)} />
            <Button title="Cancel" onPress={()=>handleDeregister(false)} />
            </View>
            </View>
        </Modal>
        </View>
        </React.Fragment>}
    </Screen>
);
    }

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    buttonsContainer: {
        width: "100%",
    },
    logo: {
        width: 200,
        height: 200,
        resizeMode: 'contain',
    },
    logoContainer: {
        position: "absolute",
        top: -20,
        alignItems: "center",
        width: "100%",
    },
    tagline: {
        fontSize: 25,
        fontWeight: "600",
        paddingVertical: 20,
    },
    TextStyle: {
         color: colors.primary,
         alignSelf: "center",
      },
    deviceBox: {
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#4eb9d6',
        padding: 5,
    },
    imageStyle: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 80,
        height: 25,
    },
    });

export default DeviceScreen;