import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import Modal from 'react-native-modal';
import WebView from "react-native-webview";
import HTML from "react-native-render-html";
import moment from 'moment-timezone';
import * as Yup from "yup";

import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
  LabelFormField,
} from "../components/forms";


import Screen from '../components/Screen';
import PopShowMessage from "../components/PopShowMessage";
import alert from '../components/alert';
import Dropdown from '../components/Dropdown';
import Button from '../components/Button';
import Switch from '../components/forms/Switch';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import colors from "../config/colors";

function MessageSettingsScreen({ route, navigation }){
    const [utc, setUTC] = useState(-5);
    const getCustomMsgs = useApi(keepConnectApi.getCustomMsgs);
    const setCustomMsgs = useApi(keepConnectApi.setCustomMsgs);
    const [visibleTrigger, setVisibleTrigger] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);  
    const [errorFromServer, setErrorFromServer] = useState(false); 
    const [serverMessage, setServerMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [custRstMsgDefault, setCustRstMsgDefault] = useState("");
    const [custAutoRstMsgDefault, setCustAutoRstMsgDefault] = useState("");
    const [custFollowerFailMsgDefault, setCustFollowerFailMsgDefault] = useState("");
    const [custCloudMsgDefault, setCustCloudMsgDefault] = useState("");
    const [custCloudRestoreMsgDefault, setCustCloudRestoreMsgDefault] = useState("");
    const [offlineDelayDefault, setOfflineDelayDefault] = useState("");
    const [custRstMsgEnable, setCustRstMsgEnable] = useState(false);
    const [custAutoRstMsgEnable, setCustAutoRstMsgEnable] = useState(false);
    const [custFollowerFailMsgEnable, setCustFollowerFailMsgEnable] = useState(false);
    const [custCloudMsgEnable, setCustCloudMsgEnable] = useState(false);
    const [enableRstMsg, setEnableRstMsg] = useState(true);
    const [enableAutoRstMsg, setEnableAutoRstMsg] = useState(true);
    const [enableFollowerFailMsg, setEnableFollowerFailMsg] = useState(true);
    const [enableCloudMsg, setEnableCloudMsg] = useState(false);
    const [enableCloudMsgDefault, setEnableCloudMsgDefault] = useState(false);
    const [offlineCloudNotificationDelay, setOfflineCloudNotificationDelay] = useState("0");
    const [keepConnectCode, setKeepConnectCode] = useState("");
    const [stagedSettings, setStagedSettings] = useState({});



    useEffect(() => {
        loadKeepConnects();
    }, []);

   const loadKeepConnects = async () => {
    //setIsLoading(true);
    const keepConnectCodeTemp = await keepConnectStore.get("keepConnect");
    setKeepConnectCode(keepConnectCodeTemp); //found this function to remove the double quotes
    const response = await getCustomMsgs.request(keepConnectCodeTemp);
    //console.log("API Response");
    //console.log(response.data);
    if (!response.ok){
        setRequestFailed(true);
        return setIsLoading(false);
    } else setRequestFailed(false);
    if(response.data){
        setCustRstMsgDefault(response.data[0].rstMsg);
        setCustAutoRstMsgDefault(response.data[0].autoRstMsg);
        setCustFollowerFailMsgDefault(response.data[0].followerFailMsg);
        setCustCloudMsgDefault(response.data[0].cloudMsg);
        setCustCloudRestoreMsgDefault(response.data[0].cloudRestorationMsg);
        setOfflineDelayDefault(response.data[0].offlineCloudNotificationDelay);
        setEnableRstMsg(response.data[0].rstMsg !== "82");
        setEnableAutoRstMsg(response.data[0].autoRstMsg !== "82");
        setEnableFollowerFailMsg(response.data[0].followerFailMsg !== "82");
        setEnableCloudMsg(response.data[0].offlineCloudNotificationDelay !== "0");
        setEnableCloudMsgDefault(response.data[0].offlineCloudNotificationDelay !== "0");
        setCustRstMsgEnable(enableRstMsg && (response.data[0].rstMsg !== null && response.data[0].rstMsg !== ""));
        setCustAutoRstMsgEnable(enableAutoRstMsg && (response.data[0].autoRstMsg !== null && response.data[0].autoRstMsg !== "") );
        setCustFollowerFailMsgEnable(enableFollowerFailMsg && (response.data[0].followerFailMsg !== null && response.data[0].followerFailMsg !== ""));
        setCustCloudMsgEnable(enableCloudMsg && (response.data[0].cloudMsg !== null && response.data[0].cloudMsg !== ""));
        //console.log(custRstMsgEnable);
      }
    setIsLoading(false);
    }

   const toggleModal = () => {
     setVisibleTrigger(!visibleTrigger);
     };

   const cancelSend = ()=> {
     setStagedSettings({});
     toggleModal();
   }

   const initiateSubmit = async ({ custRstMsg, custAutoRstMsg, custFollowerFailMsg, custCloudMsg, onMsg, offlineDelay}) => {
    const keepConnectCodeTemp = await keepConnectStore.get("keepConnect");
    setStagedSettings({ 
        rstMsg : (enableRstMsg ? (custRstMsgEnable ? custRstMsg : "") : "82"),
        autoRstMsg : (enableAutoRstMsg ? (custAutoRstMsgEnable ? custAutoRstMsg : "") : "82"),
        followerFailMsg : (enableFollowerFailMsg ? (custFollowerFailMsgEnable ? custFollowerFailMsg : "") : "82"),
        cloudMsg : (enableCloudMsg ? (custCloudMsgEnable ? custCloudMsg : "") : ""),
        cloudRestorationMsg : (enableCloudMsg ? (custCloudMsgEnable ? onMsg : "") : ""),
        offlineCloudNotificationDelay: (enableCloudMsg ? offlineDelay : "0"),
        changedCloudNotifications: ((enableCloudMsg != enableCloudMsgDefault || (enableCloudMsg && offlineCloudNotificationDelay != offlineDelayDefault)) ? "changed" : ""),
        userMac: keepConnectCodeTemp.replace(/\"/g, ""),
      });
       toggleModal();
       };

       const handleSubmit = async () => {
        toggleModal();
        //console.log("submitting");
        //console.log(stagedSettings);
        const response = await setCustomMsgs.request(stagedSettings);
        //console.log(response);
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        //setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          //setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Custom Message Have Been Saved!",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        route.params.changedDevices(true); //for refreshing upstream screen
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        };

    const validationSchema = Yup.object().shape({
        custRstMsg: Yup.string().max(100).nullable().label("Reset Message"),
        custAutoRstMsg: Yup.string().max(100).nullable().label("Auto Reset Message"),
        custFollowerFailMsg: Yup.string().max(100).nullable().label("Follower Fail Message"),
        custCloudMsg: Yup.string().max(98).nullable().label("Cloud Message"),
        onMsg: Yup.string().max(98).nullable().label("Restore Message"),
        offlineDelay: Yup.number().when('enableMsg', {is: ()=> offlineCloudNotificationDelay !== "0", then: Yup.number().required().min(10).label("Offline Delay")}),
      });

    return(
    <Screen>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Message Settings</Text>
        <Text style={{alignSelf:"center"}}>{route.params.item.Name}</Text>
        {!isLoading ? 
        <>
        <ErrorMessage
        error="Unable Communicate With Server"
        visible={requestFailed}
        />
        <Form
        initialValues={{ custRstMsg: (custRstMsgDefault !== "82" ? custRstMsgDefault : null), custAutoRstMsg : (custAutoRstMsgDefault !== "82" ? custAutoRstMsgDefault : null), custFollowerFailMsg : (custFollowerFailMsgDefault !== "82" ? custFollowerFailMsgDefault : null), custCloudMsg : (offlineCloudNotificationDelay !== "0" ? custCloudMsgDefault : null), onMsg : (offlineCloudNotificationDelay !== "0" ? custCloudRestoreMsgDefault : null), offlineDelay: (offlineCloudNotificationDelay !== "0" ? offlineCloudNotificationDelay : "20")  }}
        onSubmit={initiateSubmit}
        validationSchema={validationSchema}
        >
        <View style={styles.deviceBox}>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Reset Message</Text>
        <Switch 
          labelText='Enable Reset Message'
          switchState={enableRstMsg}
          setSwitchState={setEnableRstMsg} 
          isDisabled = {false}
        />
        {enableRstMsg && 
        <>
        <Switch 
          labelText='Customize Reset Message'
          switchState={custRstMsgEnable}
          setSwitchState={setCustRstMsgEnable} 
          isDisabled = {false}
        />
        {custRstMsgEnable && <LabelFormField
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="default"
        name="custRstMsg"
        placeholder=""
        labelText = {"Enter Custom Reset Message"}
        multiline={true}
        maxLength  = {255}
        textAlignVertical = "top"
        />}
        </>}
        <PopShowMessage>Just got back connection after resetting router.
          Number of Resets: [] 
          ID for Webstatus: []
          Cause: []

          For Premium Online Cloud Services use code:
          []</PopShowMessage>
        </View>
        <View style={styles.deviceBox}>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Auto Reset Message</Text>
        <Switch 
          labelText='Enable Auto Reset Message'
          switchState={enableAutoRstMsg}
          setSwitchState={setEnableAutoRstMsg} 
          isDisabled = {false}
        />
        {enableAutoRstMsg && 
        <>
        <Switch 
          labelText='Customize Auto Reset Message'
          switchState={custAutoRstMsgEnable}
          setSwitchState={setCustAutoRstMsgEnable} 
          isDisabled = {false}
        />
        {custAutoRstMsgEnable && <LabelFormField
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="default"
        name="custAutoRstMsg"
        placeholder=""
        labelText = {"Enter Custom Auto Reset Message"}
        multiline={true}
        maxLength  = {255}
        textAlignVertical = "top"
        />}
        </>}
        <PopShowMessage>Auto Reset Complete. 
          Number of Resets: [] 
          ID for Webstatus: []
          For Premium Online Cloud Services use code:
          []
        </PopShowMessage>
        </View>
        <View style={styles.deviceBox}>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Follower Failure Message</Text>
        <Switch 
          labelText='Enable Follower Failure Message'
          switchState={enableFollowerFailMsg}
          setSwitchState={setEnableFollowerFailMsg} 
          isDisabled = {false}
        />
        {enableFollowerFailMsg && 
        <>
        <Switch 
          labelText='Customize Follower Failure Message'
          switchState={custFollowerFailMsgEnable}
          setSwitchState={setCustFollowerFailMsgEnable} 
          isDisabled = {false}
        />
        {custFollowerFailMsgEnable && <LabelFormField
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="default"
        name="custFollowerFailMsg"
        placeholder=""
        labelText = {"Enter Custom Follower Failure Message"}
        multiline={true}
        maxLength  = {255}
        textAlignVertical = "top"
        />}
        </>}
        <PopShowMessage>One or more Follower Keep Connects may have failed to reset. After sending reset signal the Master Keep Connect waited for 30s and never recieved acknowedge signal from Follower Keep Connect(s).</PopShowMessage>
        </View>
        <View style={styles.deviceBox}>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Cloud Notifications Message</Text>
        <Switch 
          labelText='Enable Cloud Notifications Message'
          switchState={enableCloudMsg}
          setSwitchState={setEnableCloudMsg} 
          isDisabled = {false}
        />
        {enableCloudMsg && 
        <>
        <LabelFormField
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="numeric"
        name="offlineDelay"
        placeholder="20"
        labelText = {"Offline Delay (In Minutes)"}
        />
        <Switch 
          labelText='Customize Cloud Notifications Message'
          switchState={custCloudMsgEnable}
          setSwitchState={setCustCloudMsgEnable} 
          isDisabled = {false}
        />
        {custCloudMsgEnable && <LabelFormField
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="default"
        name="custCloudMsg"
        placeholder=""
        labelText = {"Enter Custom Device Offline Message"}
        multiline={true}
        maxLength  = {255}
        textAlignVertical = "top"
        />}
        {custCloudMsgEnable && <LabelFormField
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="default"
        name="onMsg"
        placeholder=""
        labelText = {"Enter Custom Device Restoration Message"}
        multiline={true}
        maxLength  = {255}
        textAlignVertical = "top"
        />}
        </>}
        <PopShowMessage>Keep Connect Cloud Services has detected internet outage for Keep Connect: [] Online registration code: []</PopShowMessage>
        </View>
        <SubmitButton title="Send Settings to Device" />
        </Form>
        <Modal isVisible={visibleTrigger} transparent={false} animationType = {"slide"} backdropColor="white">
                <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                <View style={{width:300}}>
                <Text style = {{alignSelf: "center"}}>Are you sure you want to save these messages for:{'\n'}{route.params.item.Name}</Text>
                <Button title="Send It!" onPress={()=>handleSubmit()} />
                <Button title="Cancel" onPress={()=>cancelSend()} />
                </View>
                </View>
        </Modal>
        </>
        :
        <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View>}
    </Screen>
);
    }

const styles = StyleSheet.create({
    deviceBox: {
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#4eb9d6',
        padding: 5,
        marginVertical: 10,
    },
    });

export default MessageSettingsScreen;