import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator, Platform } from "react-native";
import WebView from "react-native-webview";
import HTML from "react-native-render-html";
import moment from 'moment-timezone';


import Screen from '../components/Screen';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import colors from "../config/colors";

function PresentSettingsScreen({ route, navigation }){
    const getKeepConnectSettingsApi = useApi(keepConnectApi.getKeepConnectSettings); 
    const [isLoading, setIsLoading ] = useState(true);
    const [refreshing, setRefreshing ] = useState(false);
    const [updateNeeded, setUpdateNeeded] = useState(false);
    const [data, setSettings] = useState();
    const [newSettings1, setNewSettings1] = useState(false); //used for adding new settings with KeepConnectV2
    const [newSettings2, setNewSettings2] = useState(false); //used for adding new settings with KeepConnectV2
    const [enablePowerPal, setPowerPal] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [appTimezone, setAppTimeZone] = useState("America/New_York");


    useEffect(() => {
        loadKeepConnects();
    }, []);


    let text = "<p></p>";

    const loadKeepConnects = async () => {
        //setIsloading(true);
        //setIsLoading(false);
        const keepConnectCode = await keepConnectStore.get("keepConnect");
        const response = await getKeepConnectSettingsApi.request(keepConnectCode);
        console.log(response);
        setSettings(response.data);

        const firmwareString = response.data[0].firmware;
        const majorVersionStart = firmwareString.indexOf("_V") + 2;
        const majorVersionEnd = firmwareString.indexOf("_", majorVersionStart);
        const majorVersion = parseInt(firmwareString.substring(majorVersionStart, majorVersionEnd), 10);

        setNewSettings2(majorVersion >= 3);
        setNewSettings1(majorVersion >= 2);

        setPowerPal(response.data[0].firmware.includes("PowerPal"));

        var then = moment(response.data[0].lastConnectTime, "YYYY-DD-MM HH:mm:ss");
        var now = moment().tz("America/New_York");
        setIsConnected(now.diff(then, 'minutes') <= 7);

        const storedTimezone = await keepConnectStore.get("kc_appTimezone");
        const correctedStoredTimezone = (storedTimezone !== null ? storedTimezone.replace(/\"/g, "") : "America/New_York");
        setAppTimeZone(correctedStoredTimezone);


        setIsLoading(false);
        setRefreshing(false);
        }

    if (updateNeeded){
        if(Platform.OS === 'web') setIsLoading(true);
        setRefreshing(true);
        setUpdateNeeded(false);
        loadKeepConnects();
    }

    const convertTimezone = (item) =>{
        moment.tz.setDefault("America/New_York");
        var timestamp = moment(item, "YYYY-DD-MM HH:mm:ss");
        return timestamp.tz(appTimezone).format('YYYY-MM-DD HH:mm:ss');
    }
    
    return(
    <Screen isLoading={refreshing} setUpdateNeeded={setUpdateNeeded}>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <React.Fragment>
        <View style={{padding: 20, borderTopWidth :3, borderTopColor: colors.primary, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Present Keep Connect Status:</Text>
        <Text style={{alignSelf: "center"}}>{route.params.id}</Text><Text></Text>
        {Platform.OS === 'web' && <Text> </Text>}
        <View style={{alignItems: "center"}}>{isConnected ? <View style={{width:200, backgroundColor: '#33cc33', borderRadius: 30, justifyContent: 'center'}}>
        <Text style={{margin: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}> Online and Connected </Text>
        </View> : 
        <View style={{width:200, backgroundColor: 'red', borderRadius: 30, alignContent: 'center'}}>
        <Text style={{margin: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}> Offline </Text>
        </View>}</View>
        {Platform.OS === 'web' && <Text> </Text>}
        <Text></Text>
        {Platform.OS === 'ios' ? <></> : <Text style={styles.TextStyle} onPress={ ()=> setUpdateNeeded(true) } >Refresh</Text>}
        <Text><Text style={{fontWeight: "bold"}}>Web ID: </Text><Text>{data[0].id}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>IP: </Text><Text>{data[0].IPaddress}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Mac: </Text><Text>{data[0].macAddress}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Last Connected: </Text><Text>{convertTimezone(data[0].lastConnectTime)} ({moment().tz(appTimezone).format('z')})</Text></Text>
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Last Reset: </Text><Text>{convertTimezone(data[0].lastResetTime)} ({moment().tz(appTimezone).format('z')})</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Number of Resets: </Text><Text>{data[0].numberOfResets}</Text></Text>}
        <Text><Text style={{fontWeight: "bold"}}>Notification Type: </Text><Text>{data[0].notificationType}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Phone Number: </Text><Text>{data[0].phoneNumber}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Email Address: </Text><Text>{data[0].emailAddress}</Text></Text>
        {!enablePowerPal && data[0].customPipelines != 1 && <Text><Text style={{fontWeight: "bold"}}>Test Domain 1: </Text><Text>{data[0].testSite1}</Text></Text>}
        {!enablePowerPal && data[0].customPipelines != 1 && <Text><Text style={{fontWeight: "bold"}}>Test Domain 2: </Text><Text>{data[0].testSite2}</Text></Text>}
        {!enablePowerPal && data[0].customPipelines != 1 && <Text><Text style={{fontWeight: "bold"}}>Poll Time (Minutes): </Text><Text>{Number(data[0].pollTime)/60000}</Text></Text>}
        {enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Heartbeat Interval(Minutes): </Text><Text>{Number(data[0].pollTime)/60000}</Text></Text>}
        {!enablePowerPal && data[0].customPipelines != 1 && <Text><Text style={{fontWeight: "bold"}}>Backup Check Delay (Seconds): </Text><Text>{Number(data[0].lastChance)/1000}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Reconnect Delay (Minutes): </Text><Text>{Number(data[0].onTime)/60000}</Text></Text>}
        {!enablePowerPal && data[0].customPipelines != 1 && <Text><Text style={{fontWeight: "bold"}}>Reset Time (Seconds): </Text><Text>{Number(data[0].resetTime)/1000}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Power-On Delay (Seconds): </Text><Text>{Number(data[0].powerOnDelay)/1000}</Text></Text>}
        {!enablePowerPal && data[0].customPipelines != 1 && <Text><Text style={{fontWeight: "bold"}}>Max Continuous Resets: </Text><Text>{data[0].maxNumberOfContinuousResets}</Text></Text>}
        {!enablePowerPal && data[0].customPipelines != 1 && <Text><Text style={{fontWeight: "bold"}}>Sustained Outage Retry (hours): </Text><Text>{Number(data[0].sustainedOutageRetry)/3600000}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>UTC Offset: </Text><Text>{data[0].UTCOffset}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Auto Reset Interval (Days): </Text><Text>{data[0].autoResetPeriod}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Auto Reset Hour of Day: </Text><Text>{data[0].autoResetHourOfDay}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Enable Daylight Savings: </Text><Text>{data[0].enableDST}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Master/Follower: </Text><Text>{data[0].enableFollower == "true" ? "Follower" : "Master"}</Text></Text>}
        {!enablePowerPal && newSettings2 && <Text><Text style={{fontWeight: "bold"}}>Comms Mode: </Text><Text>{(() => {
                switch(data[0].masterFollowerMode) {
                    case "0":
                        return "Hybrid (KeepLink and LAN)";
                        break;
                    case "1":
                        return "KeepLink (Peer to Peer)"; 
                        break;
                    case "2":
                        return "LAN (uses WiFi Network)";
                        break;
                }
                })()}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Monitor Mode: </Text><Text>{(() => {
                if (data[0].enableKCRoundtrip == "true") {
                    return "Keep Connect Roundtrip";
                } else if(data[0].customPipelines == 1){
                    return "Custom Pipelines"
                } else {
                    return data[0].enablePingOnly == "true" ? "Ping Only" : "Full Stack";
                }
                })()}</Text></Text>}
        {!enablePowerPal && newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Socket Timeout: </Text><Text>{data[0].socketTimeout}</Text></Text>}
        {!enablePowerPal && data[0].customPipelines != 1 && <Text><Text style={{fontWeight: "bold"}}>Enable DNS Reset: </Text><Text>{data[0].enableDNSreset}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Enable Static IP: </Text><Text>{data[0].enableStaticIP}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Local IP: </Text><Text>{data[0].localIP}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Local Subnet: </Text><Text>{data[0].localSubnet}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Local Gateway: </Text><Text>{data[0].localGateway}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Local DNS1: </Text><Text>{data[0].localDNS1}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Local DNS2: </Text><Text>{data[0].localDNS2}</Text></Text>}
        {!enablePowerPal && data[0].customPipelines != 1 && newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Loss of WiFi Reset Mode: </Text><Text>{(() => {
                switch(data[0].lossOfWiFiMode) {
                    case "0":
                        return "Reset Regularly";
                        break;
                    case "1":
                        return "Reboot/Retry before Resetting"; 
                        break;
                    case "2":
                        return "Skip Resets on WiFi Failure";
                        break;
                }
                })()}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>802.11 Wireless Mode: </Text><Text>{(() => {
                switch(data[0].wifiMode) {
                    case "1":
                        return "802.11b";
                        break;
                    case "2":
                        return "802.11b/g"; 
                        break;
                    case "3":
                        return "802.11b/g/n";
                        break;
                }
                })()}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Reduce Wireless Transmit Power: </Text><Text>{data[0].reduceTransmit==1 ? "true" : "false"}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>WiFi SSID: </Text><Text>{data[0].customSSID}</Text></Text>}
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Web Portal Enabled: </Text><Text>{data[0].webPortal}</Text></Text>}
        {typeof data[0].vpnMode !== 'undefined' ? (
                <Text>
                    <Text style={{fontWeight: "bold"}}>VPN Mode: </Text>
                    <Text>
                    {data[0].vpnMode == '1' ? 'Auto' : 
                        data[0].vpnMode == '2' ? 'Direct' : 'Disabled'}
                    </Text>
                </Text>
                ) : (
                <Text><Text style={{fontWeight: "bold"}}>VPN Mode: </Text><Text>Disabled</Text></Text>
                )}
        {data[0].vpnMode == '1' || data[0].vpnMode == '2' ? (
                <Text><Text style={{fontWeight: "bold"}}>VPN Port: </Text><Text>{data[0].vpnPort}</Text></Text>
                ) : null}
        <Text><Text style={{fontWeight: "bold"}}>Cloud Notification Delay (Min): </Text><Text>{data[0].offlineCloudNotificationDelay == 0 ? "Off" : data[0].offlineCloudNotificationDelay}</Text></Text>
        {newSettings1 && <Text><Text style={{fontWeight: "bold"}}>Firmware Version: </Text><Text>{data[0].firmware}</Text></Text>}
        {!enablePowerPal && <Text><Text style={{fontWeight: "bold"}}>Remote Reboot: </Text><Text>{data[0].remoteReboot == 1 ? "Staged" : "Not Staged"}</Text></Text>}
        <Text><Text style={{fontWeight: "bold"}}>Remote Settings Change: </Text><Text>{data[0].remoteSettingsChange == 1 ? "Staged" : "Not Staged"}</Text></Text>
        </View>
      </React.Fragment>}
    </Screen>
);
    }

const styles = StyleSheet.create({
    TextStyle: {
            color: colors.primary,
            alignSelf: "center",
        },
    });

export default PresentSettingsScreen;

/*
text += "<b>Remote Reboot: </b>" + (data[0].remoteReboot == 1 ? "Staged" : "Not Staged") + "<br>";
text += "<b>Remote Settings Change: </b>" + (data[0].remoteSettingsChange == 1 ? "Staged" : "Not Staged") + "<br>";
*/