import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator, Platform } from "react-native";
import WebView from "react-native-webview";
import HTML from "react-native-render-html";
import moment from 'moment-timezone';


import Screen from '../components/Screen';
import Dropdown from '../components/Dropdown';
import Button from '../components/Button';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import colors from "../config/colors";

import { ErrorMessage, ErrorMessage2 } from "../components/forms";

function AppSettingsScreen({ route, navigation }){
    const [timeZone, setTimezone] = useState();
    const getAppSettings = useApi(keepConnectApi.getAppSettings);
    const setAppSettings = useApi(keepConnectApi.setAppSettings);
    const [requestFailed, setRequestFailed] = useState(false);   
    const [isLoading, setIsLoading] = useState(true);
    const [dropArray, setDropArray] = useState([]);


    useEffect(() => {
        loadKeepConnects();
    }, []);

   const loadKeepConnects = async () => {
    //setIsLoading(true);
    const storedTimezone = await keepConnectStore.get("kc_appTimezone");
    const correctedStoredTimezone = (storedTimezone !== null ? storedTimezone.replace(/\"/g, "") : null);
    //console.log("Stored Response:");
    //console.log(storedTimezone);
    const response = await getAppSettings.request();
    //console.log("API Response");
    //console.log(response.data.kc_appTimezone);
    if (!response.ok){
        setRequestFailed(true);
        if (correctedStoredTimezone !==null) setTimezone(correctedStoredTimezone);
        return setIsLoading(false);
    } else setRequestFailed(false);
    if(response.data && response.data.kc_appTimezone){
        const serverTimeZone = response.data.kc_appTimezone;
        setTimezone(serverTimeZone);
        if(serverTimeZone !== correctedStoredTimezone){
            await keepConnectStore.store("kc_appTimezone", serverTimeZone);
        }
    } else if (correctedStoredTimezone !==null) setTimezone(correctedStoredTimezone);
    
    let jsonObj = [];
    (moment.tz.names()).forEach(function(key){
        jsonObj.push({
            label: key,
            value: key
        });
    });
    setDropArray(jsonObj);
    setIsLoading(false);

    };
   
    const handleSave = async () => {
        setIsLoading(true);
        const response = await setAppSettings.request({kc_appTimezone : timeZone});
        //console.log(response);
        await keepConnectStore.store("kc_appTimezone", timeZone);
        if (!response.ok || response.data.MSG !== "Success!"){
            setRequestFailed(true);
            return setIsLoading(false);
        } else setRequestFailed(false);
        setIsLoading(false);
    }

    
    
    return(
    <Screen>
        <Text style={{paddingTop: 20, fontWeight: "bold", alignSelf: "center"}}>App Settings</Text>
        {!isLoading ? 
        <>
        <ErrorMessage
        error="Unable Communicate With Server"
        visible={requestFailed}
        />
        <Dropdown
          labelText={"Select Timezone"}
          defaultValueProp={timeZone}
          itemsArray= {dropArray}
          onChangeItemProp={setTimezone}
        />
        <Button
            title="Save Settings"
            onPress={() => handleSave()}
        />
        </>
        :
        <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View>}
    </Screen>
);
    }

export default AppSettingsScreen;

/*
text += "<b>Remote Reboot: </b>" + (data[0].remoteReboot == 1 ? "Staged" : "Not Staged") + "<br>";
text += "<b>Remote Settings Change: </b>" + (data[0].remoteSettingsChange == 1 ? "Staged" : "Not Staged") + "<br>";
*/