import React from 'react';
import { Text, View } from 'react-native';

import Screen from '../components/Screen';
import Button from '../components/Button';
import routes from "../navigation/routes";

function TriggerResetScreen({ navigation }){
    return(
    <Screen>
        <Text>Trigger Reset Screen</Text>
    </Screen>
);
    }

export default TriggerResetScreen;