import React, {useState} from "react";
import Constants from "expo-constants";
import { StyleSheet, SafeAreaView, View, TouchableOpacity, Image, Text, Linking,RefreshControl} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import { MaterialIcons } from '@expo/vector-icons';
import Modal from 'react-native-modal';

import Button from '../components/Button';
import useAuth from "../auth/useAuth";
import routes from "../navigation/routes";
import colors from '../config/colors';

function Screen({ children, style, navigation, bwj_upStreamRefreshNeeded, isLoading, setUpdateNeeded }) {
  const [visibleTrigger, setVisibleTrigger] = useState(false);
  const auth = useAuth();

  const toggleModal = () => {
    setVisibleTrigger(!visibleTrigger);
    };

  return (
    <SafeAreaView style={[styles.screen, style]}>
      <KeyboardAwareScrollView 
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{paddingBottom: 150}} 
      refreshControl={setUpdateNeeded !== undefined && isLoading !== undefined &&
          <RefreshControl
            refreshing={isLoading}
            onRefresh={()=>setUpdateNeeded(true)}
          />}>
      <View  style={{flex:1}}>
      {navigation !== undefined && !isLoading ? <TouchableOpacity style={styles.settingsButton} onPress={()=>toggleModal()}>
      <MaterialIcons name="settings" size={32} color="#4eb9d6" />
      </TouchableOpacity> : <></>}
      <View style={styles.logoContainer}>
        <Image 
            source= {require('../assets/KeepConnectlogoblankback.png')}
            style = {styles.logo}
        />
        </View> 
      <View style={[styles.view, style]}>{children}</View>
      <Modal isVisible={visibleTrigger} transparent={false} animationIn="slideInLeft" animationOut="slideOutLeft" backdropColor="white">
            <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Are you sure you want to send a reset to:{'\n'}</Text>
            <Button title="Add/Remove Keep Connects" onPress={()=>{setVisibleTrigger(false); navigation.navigate(routes.ADDREMOVEKEEPCONNECTS, {changedDevices: setUpdateNeeded});}} />
            <Button title="Cloud Notifications" onPress={()=>{setVisibleTrigger(false); navigation.navigate(routes.CLOUDNOTIFICATIONS);}} />
            <Button title="App Settings" onPress={()=>{setVisibleTrigger(false); navigation.navigate(routes.APPSETTINGS);}} />
            <Button title="Logout" onPress={()=>auth.logOut()} />
            <Button title="Back" onPress={()=>setVisibleTrigger(false)} />
            <View style={{paddingVertical: 10, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle} onPress={ ()=> Linking.openURL('mailto: support@johnson-creative.com') } >Support</Text></View><View style={{flex: 1}}></View><View style={{flex: 1}}><Text style={styles.TextStyle} onPress={ ()=> Linking.openURL('https://www.johnson-creative.com/keep-connect-cloud-services-mobile-app-privacy-policy/') } >Privacy</Text></View></View>
            </View>
            </View>
        </Modal>
      </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  view: {
    flex: 1,
    minWidth: 200,
    top: 150,
    padding: 20
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    alignItems: "center",
    width: "100%",
},
  settingsButton: {
    position: "absolute",
    zIndex: 1,
    top: 10,
    right: 10,
    alignItems: "center",
    height: 30,
    width: 30,
},
logo: {
  width: 200,
  height: 200,
  resizeMode: 'contain',
},
TextStyle: {
     color: colors.primary,
     alignSelf: "center",
  }
});

export default Screen;
