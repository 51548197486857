import React, { useState, useEffect } from "react";
import { StyleSheet, Image, View, Text, ActivityIndicator, Platform } from "react-native";
import Modal from 'react-native-modal';
import * as Yup from "yup";
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import * as DocumentPicker from 'expo-document-picker';

import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
  LabelFormField,
} from "../components/forms";

import Screen from '../components/Screen';
import alert from '../components/alert';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import Switch from '../components/forms/Switch';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import colors from "../config/colors";
import { setStatusBarHidden } from "expo-status-bar";


function ChangeSettingsScreenPowerPal({ route, navigation }){
    const getKeepConnectSettingsApi = useApi(keepConnectApi.getKeepConnectSettings);
    const changeSettingsKeepConnectApi = useApi(keepConnectApi.triggersKeepConnectApi); 
    const [data, setExistingSettings] = useState(); 
    const [newSettings1, setNewSettings1] = useState(false); //used for adding new settings with KeepConnectV2 
    const [newSettings2, setNewSettings2] = useState(false); //used for adding new settings with KeepConnectV3
    const [isLoading, setIsLoading] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [selectedNotifications, setSelectedNotifications] = useState("noNotif");
    const [enableAdvanced, setEnableAdvanced] = useState(false);
    const [enableStaticIP, setEnableStaticIP] = useState(false);
    const [reduceTxPower, setReduceTxPower] = useState(false);
    const [enableCustomSSID, setEnableCustomSSID] = useState(false);
    const [wifiResetMode, setSelectedWiFiResetMode] = useState("1");
    const [wifi802_11Mode, setSelected802_11Mode] = useState("3");
    const [enableWebPortal, setEnableWebPortal] = useState(true);
    const [connectionMonitorMode, setConnectionMonitorMode] = useState("Fullstack");
    const [keepConnectCode, setKeepConnectCode] = useState("");
    const [stagedSettings, setStagedSettings] = useState({});
    const [visibleTrigger, setVisibleTrigger] = useState(false);
    const [visibleTrigger2, setVisibleTrigger2] = useState(true);
    const [visibleTrigger3, setVisibleTrigger3] = useState(false);
    const [defaultPhoneNumber, setDefaultPhoneNumber] = useState("XXXXXXXXXX");
    const [defaultCountryCode, setDefaultCountryCode] = useState("1");
    const [defaultEmailAddress, setDefaultEmailAddress] = useState("");
    const [defaultPollTime, setDefaultPollTime] = useState("1");
    const [defaultOffline, setDefaultOffline] = useState("6");
    const [defaultOnMsg, setDefaultOnMsg] = useState("Power Pal: Connection Restored");
    const [defaultOffMsg, setDefaultOffMsg] = useState("Power Pal: Connection Offline");
    const [defaultLocalIP, setDefaultLocalIP] = useState("");
    const [defaultLocalSubnet, setDefaultLocalSubnet] = useState("");
    const [defaultLocalGateway, setDefaultLocalGateway] = useState("");
    const [defaultLocalDNS1, setDefaultLocalDNS1] = useState("");
    const [defaultLocalDNS2, setDefaultLocalDNS2] = useState("");
    const [defaultLocalSSID, setDefaultLocalSSID] = useState("");
    const [vpnEnabled, setVPNenabled] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [vpnPort, setVPNport] = useState("4550");
    const [vpnMode, setVPNmode] = useState("0");
    const [saveForExport, setSaveForExport] = useState(false);


    const validationSchema = Yup.object().shape({
      email: Yup.string().email().label("Email"),
      phoneNumber: Yup.string().required().label("Phone Number"), 
      countryCode: Yup.string().required().max(3).label("Country Code"), 
      pollPeriod: Yup.string().required().min(1).label("Poll Period"), 
      offline: Yup.number().required().label("Offline"), 
      onMsg: Yup.string().required().max(98).label("onMsg"), 
      offMsg: Yup.string().required().max(98).label("offMsg"), 
      ip: Yup.string().when('enableIP', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter IP Address")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid IP Address").label("Local IP Address"), //.when('eStaticIP', {is: true, then: Yup.string().required("Must enter IP Address")})
      sm: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter Subnet Mask")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid Subnet Mask").label("Local Subnet Mask"), 
      gw: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter Gateway IP")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid Gateway IP").label("Local Gateway"), 
      dns1: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter DNS Server 1")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid DNS1 IP").label("Local DNS1"), 
      dns2: Yup.string().matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid DNS2 IP ").label("Local DNS2"), 
      SSID: Yup.string().max(20).label("Custom WiFi SSID"),    
      vpnP: Yup.number().required().min(0).max(65535).label("VPN Port"),   
    });
    

    useEffect(() => {
         loadKeepConnects();
     }, []);

    const loadKeepConnects = async () => {
      const keepConnectCodeTemp = await keepConnectStore.get("keepConnect");
      setKeepConnectCode(keepConnectCodeTemp.replace(/\"/g, "")); //found this function to remove the double quotes
      const storedLicense = await keepConnectStore.get("license");
      const correctedStoredLicense = (storedLicense !== null ? storedLicense.replace(/\"/g, "") : "basic");
      setEnterprise(correctedStoredLicense == "enterprise");
      }

    const toggleModal = () => {
      setVisibleTrigger(!visibleTrigger);
      };

    const cancelSend = ()=> {
      setStagedSettings({});
      toggleModal();
    }

    const initiateSubmit = async ({ email, phoneNumber, countryCode, pollPeriod, offline, onMsg, offMsg, ssid, ip, sm, gw, dns1, dns2, vpnP}) => {
        let settingsTemp = { 
                notif: selectedNotifications, 
                eadd: email, 
                pn: phoneNumber, 
                cc: countryCode, 
                pollt: pollPeriod,
                offline: offline,
                onMsg : onMsg,
                offMsg : offMsg, 
                webPortal : (enableWebPortal ? 0:82), //82 is the disable code within device firmware
                SSID : (enableCustomSSID ? ssid : ""),
                enableStaticIP : (enableStaticIP ? 82:0),
                ip : ip,
                sm : sm,
                gw : gw,
                dns1 : dns1,
                dns2 : dns2,
                mfc : 2, //set to 2 to ensure KeepLink is always turned off for PowerPal
                tx : (reduceTxPower ? 1:0),
                wr : wifiResetMode,
                wm : wifi802_11Mode,
                vpnMode : vpnMode,
                vpnPort : vpnP,
                keepConnects: keepConnectCode, 
                triggerType: "remoteSettingsChange",
                powerPal : 1,
              };
        setStagedSettings(settingsTemp);
        if(saveForExport){
          saveSettingsToFile(settingsTemp);
          return;
        }
        toggleModal();
        };

    // Function to load settings from a file
    async function loadSettingsFromFile() {
      // Use the document picker to choose a file
      const file = await DocumentPicker.getDocumentAsync({});
    
      // If a file was selected
      if (file.type === 'success') {
        setIsLoading(true);
        let json;
        let settings;
        if (Platform.OS === 'web') {
          // Handle web platform
          const response = await fetch(file.uri);
          const blob = await response.blob();
    
          // Wrap the FileReader logic in a Promise
          const readFile = new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (event) => {
              json = event.target.result;
              settings = JSON.parse(json);
              resolve(); // Resolve the Promise when done
            };
    
            reader.onerror = reject; // Reject the Promise on error
    
            reader.readAsText(blob);
          });
    
          // Wait for the Promise to resolve before continuing
          await readFile;
        } else {
          // Handle native platform
          json = await FileSystem.readAsStringAsync(file.uri);
          
          // Parse the JSON
          settings = JSON.parse(json);
          // ... rest of your code remains the same ...
        }

        //console.log(settings);
    
        if (settings.notif !== undefined) setSelectedNotifications(settings.notif);
        if (settings.eadd !== undefined) setDefaultEmailAddress(settings.eadd);
        if (settings.pn !== undefined) setDefaultPhoneNumber(settings.pn);
        if (settings.cc !== undefined) setDefaultCountryCode(settings.cc);
        if (settings.pollt !== undefined) setDefaultPollTime(settings.pollt);
        if (settings.offline !== undefined) setDefaultOffline(settings.offline);
        if (settings.onMsg !== undefined) setDefaultOnMsg(settings.onMsg);
        if (settings.offMsg !== undefined) setDefaultOffMsg(settings.offMsg);
        if (settings.webPortal !== undefined) setEnableWebPortal(settings.webPortal === 0);
        if (settings.SSID !== undefined){
          setEnableCustomSSID(settings.SSID !== "");
          if(settings.SSID !== "") setDefaultLocalSSID(settings.SSID);
        }
        if (settings.enableStaticIP !== undefined) setEnableStaticIP(settings.enableStaticIP === 82);
        if (settings.ip !== undefined) setDefaultLocalIP(settings.ip);
        if (settings.sm !== undefined) setDefaultLocalSubnet(settings.sm);
        if (settings.gw !== undefined) setDefaultLocalGateway(settings.gw);
        if (settings.dns1 !== undefined) setDefaultLocalDNS1(settings.dns1);
        if (settings.dns2 !== undefined) setDefaultLocalDNS2(settings.dns2);
        if (settings.tx !== undefined) setReduceTxPower(settings.tx === 1);
        if (settings.wr !== undefined) setSelectedWiFiResetMode(settings.wr);
        if (settings.wm !== undefined) setSelected802_11Mode(settings.wm);
        if (settings.vpnMode !== undefined) setVPNmode(settings.vpnMode);
        if (settings.vpnPort !== undefined) setVPNport(settings.vpnPort);
        //if (settings.keepConnects !== undefined) setKeepConnectCode(settings.keepConnects);
      }
      setIsLoading(false);
      setVisibleTrigger3(false);
    }

    async function saveSettingsToFile(settingsTemp) {
      // Create a settings object
      let settings = {};
      
      // If includeSettings is true, add all your settings
      // Add all your settings to the settings object
      settings = settingsTemp;
    
      // Create a JSON string from the settings object
      const json = JSON.stringify(settings);
    
      if (Platform.OS === 'web') {
        // Handle web platform
        const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
    
        a.href = url;
        a.download = 'settings.json';
        a.click();
    
        URL.revokeObjectURL(url);
        setVisibleTrigger3(false);
      } else {
        // Handle native platform
        const fileUri = FileSystem.documentDirectory + 'settings.json';
        await FileSystem.writeAsStringAsync(fileUri, json);
    
        if (!(await Sharing.isAvailableAsync())) {
          alert(`Sharing is not available on your platform`);
          return;
        }
        await Sharing.shareAsync(fileUri);
        setVisibleTrigger3(false);
      }
    }

    const loadExistingSettings = async (useDefaults) => {
      //setIsloading(true);
      //setIsLoading(false);
      const keepConnectCode = await keepConnectStore.get("keepConnect");
      const response = await getKeepConnectSettingsApi.request(keepConnectCode);
      //setExistingSettings(response.data);
      //console.log(response);
      //console.log(response.data[0]);
      setVPNenabled(response.data[0].firmware.includes("VPN"));

      const firmwareString = response.data[0].firmware;
      const majorVersionStart = firmwareString.indexOf("_V") + 2;
      const majorVersionEnd = firmwareString.indexOf("_", majorVersionStart);
      const majorVersion = parseInt(firmwareString.substring(majorVersionStart, majorVersionEnd), 10);

      setNewSettings2(majorVersion >= 3);
      setNewSettings1(majorVersion >= 2);

      //setNewSettings1(true);
      if(!useDefaults){

        //Do all the mapping here!
        //data[0].notificationType
        //console.log(response.data[0]);

        setSelectedNotifications(response.data[0].notificationType == "None" ? "noNotif" : response.data[0].notificationType);
        var StringPhoneNumber = String(response.data[0].phoneNumber);
        var PhoneNumberLength = StringPhoneNumber.length;
        if(PhoneNumberLength > 10){
          setDefaultCountryCode(StringPhoneNumber.substring(0, (PhoneNumberLength - 10)));
          setDefaultPhoneNumber(StringPhoneNumber.substring((PhoneNumberLength - 10), PhoneNumberLength));
        }
        setDefaultEmailAddress(response.data[0].emailAddress);
        setDefaultPollTime(String(Number(response.data[0].pollTime)/60000));
        setDefaultOffline(String(Number(response.data[0].offlineCloudNotificationDelay)));
        setDefaultOffMsg(String(response.data[0].cloudMsg));
        setDefaultOnMsg(String(response.data[0].cloudRestorationMsg));
        if(response.data[0].enableStaticIP == "true"){
          setEnableStaticIP(true);
          setDefaultLocalIP(response.data[0].localIP);
          setDefaultLocalSubnet(response.data[0].localSubnet);
          setDefaultLocalGateway(response.data[0].localGateway);
          setDefaultLocalDNS1(response.data[0].localDNS1);
          setDefaultLocalDNS2(response.data[0].localDNS2);
        } else{
          setEnableStaticIP(false);
          setDefaultLocalIP("");
          setDefaultLocalSubnet("");
          setDefaultLocalGateway("");
          setDefaultLocalDNS1("");
          setDefaultLocalDNS2("");
        }
        setSelected802_11Mode(response.data[0].wifiMode);
        setReduceTxPower(Number(response.data[0].reduceTransmit) == 1 ? true: false);
        let StringSSID = String(response.data[0].customSSID);
        let StringMAC = String(response.data[0].macAddress);
        setVPNmode(response.data[0].vpnMode != null ? response.data[0].vpnMode : "0");
        setVPNport(response.data[0].vpnPort != null ? response.data[0].vpnPort : "4550");

        let checkIfDefaultSSID = (response.data[0].firmware.includes("PowerPal") ? "PowerPal-" : "KeepConnect-") + (StringMAC.substring(StringMAC.length - 5, StringMAC.length)).replace(":", "");
        //console.log(checkIfDefaultSSID);
        if(StringSSID != checkIfDefaultSSID){
        setEnableCustomSSID(true);
        setDefaultLocalSSID(response.data[0].customSSID);
        } else{
          setEnableCustomSSID(false);
          setDefaultLocalSSID("");
        }
        setEnableWebPortal(response.data[0].webPortal == "true" ? true : false);
        if (response.data[0].enableKCRoundtrip == "true") {
          setConnectionMonitorMode("KCRoundtrip");
        } else {
            setConnectionMonitorMode(response.data[0].enablePingOnly == "true" ? "Only Monitor Using Ping" : "Fullstack");
        }
      }
      setIsLoading(false);
      }

    const initializeSettings = async (useDefaults) => {
      setVisibleTrigger2(!visibleTrigger2);
      setIsLoading(true);
      loadExistingSettings(useDefaults);
      
    };

    const handleSubmit = async () => {
        toggleModal();
        const response = await changeSettingsKeepConnectApi.request(stagedSettings);
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Settings were staged successfully. Your device will download and apply these in appx 5min.",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        route.params.changedDevices(true); //for refreshing upstream screen
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        };

    
    return(
    <Screen>
        {isLoading ? <ActivityIndicator size="large" color={colors.primary}/> :
        <React.Fragment>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Enter New Settings for Keep Connect:</Text>
        <Text style={{alignSelf: "center"}}>{route.params.item.Name}</Text>
        <View style={{padding: 20, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <Form
        initialValues={{ email: defaultEmailAddress, phoneNumber: defaultPhoneNumber, countryCode: defaultCountryCode, pollPeriod: defaultPollTime, offline: defaultOffline, onMsg: defaultOnMsg, offMsg: defaultOffMsg, ip: defaultLocalIP, sm: defaultLocalSubnet, gw: defaultLocalGateway, dns1: defaultLocalDNS1, dns2: defaultLocalDNS2, ssid: defaultLocalSSID, vpnP: vpnPort }} //order doesn't matter since these are key:values
        onSubmit={initiateSubmit}
        validationSchema={validationSchema}
        >
        <Dropdown
          labelText='Select Notification Type'
          defaultValueProp={selectedNotifications}
          itemsArray= {[
            {label: 'No Notifications', value: 'noNotif'},
            {label: 'Email Notifications', value: 'Email' },
            {label: 'SMS Notifications', value: 'SMS' },
            ]}
          onChangeItemProp={setSelectedNotifications}
        />
        {selectedNotifications==='Email' && <FormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="email"
          keyboardType="email-address"
          name="email"
          placeholder="Email"
          textContentType="emailAddress"
        />}
        
        {selectedNotifications==='SMS' && <LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="cellphone"
          keyboardType="numeric"
          name="phoneNumber"
          placeholder="XXXXXXXXXX"
          labelText='Enter Phone Number (10 Digits)'
        />}
        {selectedNotifications==='SMS' && <LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="plus"
          keyboardType="numeric"
          name="countryCode"
          placeholder="1"
          labelText = "Enter Country Code"
        />}
        {<LabelFormField
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="default"
        name="offMsg"
        placeholder=""
        labelText = {"Offline Message"}
        multiline={true}
        maxLength  = {98}
        textAlignVertical = "top"
        />}
        {<LabelFormField
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="default"
        name="onMsg"
        placeholder=""
        labelText = {"Restoration Message"}
        multiline={true}
        maxLength  = {98}
        textAlignVertical = "top"
        />}
        {<LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          keyboardType="numeric"
          name="pollPeriod"
          placeholder="1"
          labelText = {"Heartbeat Interval\n(Minutes)"}
        />}
        {<LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          keyboardType="numeric"
          name="offline"
          placeholder="6"
          labelText = {"Offline Notification Delay\n(Minutes)"}
        />}
        <Switch 
          labelText='Show Advanced Settings'
          switchState={enableAdvanced}
          setSwitchState={setEnableAdvanced} 
          isDisabled = {false}
        />
        {enableAdvanced && newSettings1 && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && newSettings1 && <Text style={{fontWeight: "bold", alignSelf: "center"}}>Miscellaneous Settings:</Text>}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Enable Static IP'
          switchState={enableStaticIP}
          setSwitchState={setEnableStaticIP} 
          isDisabled = {false}
        />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="ip"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"IP Address"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="sm"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"Subnet Mask"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="gw"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"Gateway"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="dns1"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"DNS Server 1"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="dns2"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"DNS Server 2"}
                  />}
        {enableAdvanced && newSettings1 && <Dropdown
          labelText='Select 802.11 Wireless Mode'
          defaultValueProp={wifi802_11Mode}
          itemsArray= {[
            {label: '802.11b/g/n', value: '3'},
            {label: '802.11b/g', value: '2' },
            {label: '802.11b', value: '1' },
            ]}
          onChangeItemProp={setSelected802_11Mode}
        />}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Reduce Wireless Transmit power'
          switchState={reduceTxPower}
          setSwitchState={setReduceTxPower} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Enable Local Web Portal'
          switchState={enableWebPortal}
          setSwitchState={setEnableWebPortal} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Use Custom Keep Connect SSID'
          switchState={enableCustomSSID}
          setSwitchState={setEnableCustomSSID} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings1 && enableCustomSSID  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="default"
                    name="ssid"
                    placeholder=""
                    labelText = {"Custom WiFi SSID"}
                  />}
        {enableAdvanced && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && <Text style={{fontWeight: "bold", alignSelf: "center"}}>VPN Endpoint Settings:</Text>}
        {enableAdvanced && <Dropdown
          key={vpnMode}
          labelText='VPN Mode'
          defaultValueProp={vpnMode}
          itemsArray= {[
            {label: 'Disabled', value: '0'},
            {label: 'Auto', value: '1' },
            {label: 'Direct', value: '2' },
          ]}
          disabled = {!vpnEnabled || !enterprise}
          onChangeItemProp={setVPNmode}
        />}
        {enableAdvanced && !enterprise && <Text style={{fontSize: 12, color: "gray"}}>VPN Functionality requires Enterprise Subscription</Text>}
        {enableAdvanced && enterprise && !vpnEnabled && <Text style={{fontSize: 12, color: "gray"}}>Firmware Upgrade Required to Enable</Text>}
        {enableAdvanced && vpnMode == 2 && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="vpnP"
                    placeholder=""
                    labelText = {"Device Listening Port"}
                  />}
        <Text></Text>
      <ErrorMessage
          error={requestMessage}
          visible={requestFailed}
        />
        <ErrorMessage
          error={serverMessage}
          visible={errorFromServer}
        />
      <SubmitButton title="Send Settings to Device" />
      <Button title="Import/Export Settings" onPress={() => setVisibleTrigger3(true)} />
        <Modal isVisible={visibleTrigger3} transparent={true} animationType = {"slide"} onBackdropPress={()=>setVisibleTrigger3(false)}>
          <View style={{height: 300,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
              <Text style={{alignSelf: "center"}}>How would you like to proceed?{'\n'}</Text>
              <Button title="Import Settings" onPress={()=>{loadSettingsFromFile();}} />
              <SubmitButton title="Export Settings" customAction={() => setSaveForExport(true)} />
            </View>
          </View>
        </Modal>
      </Form>
      <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"}>
            <View style={{height: 250,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style = {{alignSelf: "center"}}>Are you sure you want to send these settings to:{'\n'}{route.params.item.Name}</Text>
            <Button title="Send It!" onPress={()=>handleSubmit()} />
            <Button title="Cancel" onPress={()=>cancelSend()} />
            </View>
            </View>
        </Modal>
        <Modal isVisible={visibleTrigger2} transparent={true} animationType = {"slide"} onBackdropPress={()=>navigation.goBack()}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>How would you like to proceed?{'\n'}</Text>
            <Button title="View/Modify Settings" onPress={()=>initializeSettings(false)} />
            <Button title="Setup from Defaults" onPress={()=>initializeSettings(true)} />
            </View>
            </View>
        </Modal>
      </View>
      </React.Fragment>}
    </Screen>
);
    }

export default ChangeSettingsScreenPowerPal;