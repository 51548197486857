import React, { useState, useEffect } from "react";
import { StyleSheet, Image, View, Text, ActivityIndicator } from "react-native";
//import DropDownPicker from 'react-native-dropdown-picker';
import Dropdown from '../components/Dropdown';
import Modal from 'react-native-modal';
import * as Yup from "yup";

import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from "../components/forms";

import Screen from '../components/Screen';
import alert from '../components/alert';
import Button from '../components/Button';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import colors from "../config/colors";

function AddRemoveKeepConnectsScreen({ route, navigation }){
    const addKeepConnectApi = useApi(keepConnectApi.registerKeepConnect);  
    const getKeepConnectApi = useApi(keepConnectApi.getKeepConnects);
    const removeKeepConnectApi = useApi(keepConnectApi.removeKeepConnect);  
    const [isLoading, setIsLoading] = useState(true);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("Error Communicating with Server");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [keepConnectsArray, setKeepConnectsArray] = useState([{label: "No Keep Connects Exist", value: "null"}]);
    const [selectedValue, setSelectedValue]= useState();
    const [visibleTrigger, setVisibleTrigger] = useState(false);


    useEffect(() => {
        loadKeepConnects();
    }, []);

    const loadKeepConnects = async () => {
        if(route.params.initialSetup === true) setVisibleTrigger(true);
        setIsLoading(true);
        const response = await getKeepConnectApi.request();
        if (!response.ok){
          setIsLoading(false);
          return setRequestFailed(true);
        }
        if(!response.data){
          setKeepConnectsArray([{label: "No Keep Connects Exist", value: "null"}]);
          setIsLoading(false);
          return;
        }
        let jsonObj = [];
        Object.keys(response.data).forEach(function(key) {
            jsonObj.push({
                label: key + " " + response.data[key],
                value: response.data[key]
            });
          });
        setKeepConnectsArray(jsonObj);
        setSelectedValue(jsonObj[0].value);
        setIsLoading(false);
    };

    const validationSchema = Yup.object().shape({
      webID: Yup.string().required().max(5).label("Web ID"),
      code: Yup.string().required().length(17).label("Keep Connect Code"),
      name: Yup.string().required().label("Name"),
    });

    const handleSubmit = async ({ webID, code, name }) => {
        setIsLoading(true);
        const response = await addKeepConnectApi.request(webID, code, name);
        if (!response.ok){
          setIsLoading(false);
          return setRequestFailed(true);
      } 
      setServerMessage("Unknown Server Error");
      if( !response.data || !response.data.MSG ){
        setIsLoading(false);
        return setErrorFromServer(true);
      }
      if (response.data.MSG != "Success!"){
        setErrorFromServer(true);
        setServerMessage(response.data.MSG);
        setIsLoading(false);
        return;
      }
      alert(
        "Success!",
        "Your Keep Connect was successfully registered!",
        [
          { text: "OK", onPress: () => {} }
        ]
      );
      setRequestFailed(false);
      setErrorFromServer(false);
      setServerMessage("");
      if(route.params.initialSetup === true){
        route.params.changedDevices(true);
        navigation.goBack();
        return;
      }
      if(route.params.changedDevices) route.params.changedDevices(true);
      loadKeepConnects();
      };

    const onChangeDropdown = (itemValue) => {
      setSelectedValue(itemValue);
  }

    const removeKeepConnect = async ()=>{
        if (selectedValue == null){
            return;
        }
        setIsLoading(true);
        const response = await removeKeepConnectApi.request(selectedValue);
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Your Keep Connect was successfully removed!",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        loadKeepConnects();
        route.params.changedDevices(true);
        };

    
    return(
    <Screen>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <React.Fragment>
        <View style={{padding: 20, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <ErrorMessage
          error={requestMessage}
          visible={requestFailed}
        />
        <ErrorMessage
          error={serverMessage}
          visible={errorFromServer}
        />
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Registered Keep Connects:</Text>
        {keepConnectsArray.map((d)=><Text key={d.label} style={{alignSelf: "center"}}>{d.label}</Text>)}
        </View>
        <Text></Text>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Register a New Device</Text>
        <View style={{padding: 20, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <Form
        initialValues={{ webID: "", code: "", name: "" }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        >
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="webID"
          keyboardType="numeric"
          placeholder="Keep Connect Web ID"
          textContentType="none"
          helpText="The Web ID can be found at the end of any SMS/Email notification recieved from your Keep Connect device. You can also visit www.johnson-creative.com/status to search for units on your network."
        />
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="code"
          placeholder="Keep Connect Code"
          textContentType="none"
          helpText="The Keep Connect Code can be found at the end of any SMS/Email notification recieved from your Keep Connect device. This is also simply the MAC Address of the unit and can also be found in your router logs, etc."
        />
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="name"
          placeholder="Nickname"
          textContentType="none"
          helpText="Assign a memorable name to your unit to help yourself easily distinguish it."
        />
        <SubmitButton title="Register Keep Connect" />
      </Form>
      </View>
      <Text></Text>
      <Text style={{fontWeight: "bold", alignSelf: "center"}}>Select a Device For Removal:</Text>
      <Dropdown
            itemsArray={keepConnectsArray}
            defaultValueProp={keepConnectsArray[0].value}
            containerStyle={{height: 40}}
            style={{backgroundColor: '#fafafa'}}
            itemStyle={{
                justifyContent: 'flex-start'
            }}
            dropDownStyle={{backgroundColor: '#fafafa'}}
            onChangeItemProp={ item => onChangeDropdown(item)}
    />
    <Button
            title="Remove Selected Keep Connect"
            onPress={removeKeepConnect}
        />
      </React.Fragment>}
    <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"}>
            <View style={{height: 350,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{fontWeight: "bold", alignSelf: "center"}}>Hello and Welcome!</Text><Text></Text>
            <Text style={{alignSelf: "center"}}>To get you started, you will just need to register one or more devices with our platform. Please use the next screen to enter your Web ID and Registration Code to get started.</Text><Text></Text>
            <Text style={{alignSelf: "center"}}>Hint: Your Web ID and Registration Code can be found at the end of any notification you recieved from your Keep Connect during the device setup. For further assistance please contact our support team.</Text>
            <Text></Text>
            <Button title="Continue" onPress={()=>setVisibleTrigger(false)} />
            </View>
            </View>
        </Modal>
    </Screen>
);
    }

export default AddRemoveKeepConnectsScreen;