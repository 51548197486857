import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import OverviewScreen from '../screens/OverviewScreen';
import DeviceScreen from '../screens/DeviceScreen';
import DashboardScreen from '../screens/DashboardScreen';
import DashboardScreenComprehensive from '../screens/DashboardScreenComprehensive';
import PresentSettingsScreen from '../screens/PresentSettingsScreen';
import PipelinesScreen from '../screens/PipelinesScreen';
import AppSettingsScreen from '../screens/AppSettingsScreen';
import MessageSettingsScreen from '../screens/MessageSettingsScreen';
import ChangeSettingsScreen from '../screens/ChangeSettingsScreen';
import ChangeSettingsScreenPowerPal from '../screens/ChangeSettingsScreenPowerPal';
import CloudNotificationsScreen from '../screens/CloudNotificationsScreen';
import TriggerResetScreen from '../screens/TriggerResetScreen';
import AddRemoveKeepConnectsScreen from '../screens/AddRemoveKeepConnect';


const Stack = createStackNavigator();

const AppNavigator = () => (
    <Stack.Navigator>
        <Stack.Screen name="OverviewScreen" component={OverviewScreen} options={{ title: "Overview Screen"}} />
        <Stack.Screen name="AddRemoveKeepConnectsScreen" component={AddRemoveKeepConnectsScreen} options={{ title: "Add/Remove Devices"}}  />
        <Stack.Screen name="DashboardScreen" component={DashboardScreen} options={{ title: "Charts"}}  />
        <Stack.Screen name="DashboardScreenComprehensive" component={DashboardScreenComprehensive} options={{ title: "Comprehensive Logs"}}  />
        <Stack.Screen name="CloudNotificationsScreen" component={CloudNotificationsScreen} options={{ title: "Cloud Notifications"}}  />
        <Stack.Screen name="PresentSettingsScreen" component={PresentSettingsScreen} options={{ title: "Device Status"}}  />
        <Stack.Screen name="PipelinesScreen" component={PipelinesScreen} options={{ title: "Configure Pipelines"}}  />
        <Stack.Screen name="AppSettingsScreen" component={AppSettingsScreen} options={{ title: "App Settings"}}  />
        <Stack.Screen name="MessageSettingsScreen" component={MessageSettingsScreen} options={{ title: "Message Settings"}}  />
        <Stack.Screen name="DeviceScreen" component={DeviceScreen} options={{ title: "Device Screen"}}  />
        <Stack.Screen name="ChangeSettingsScreen" component={ChangeSettingsScreen} options={{ title: "Device Settings"}}  />
        <Stack.Screen name="ChangeSettingsScreenPowerPal" component={ChangeSettingsScreenPowerPal} options={{ title: "Device Settings"}}  />
        <Stack.Screen name="TriggerResetScreen" component={TriggerResetScreen} />
    </Stack.Navigator>
);

export default AppNavigator;