import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Linking, ActivityIndicator, RefreshControl, Platform, Image} from 'react-native';
import moment from 'moment-timezone';
import { Foundation } from '@expo/vector-icons';
import { Popable } from 'react-native-popable';
//import { Dropdown } from 'react-native-material-dropdown';
//import RNPickerSelect from "@react-native-community/picker";

import Screen from '../components/ScreenWithoutScrollView';
import Button from '../components/Button';
import routes from "../navigation/routes";
import useAuth from "../auth/useAuth";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import { ErrorMessage, ErrorMessage2 } from "../components/forms";
import colors from '../config/colors';
import { TouchableOpacity } from 'react-native-gesture-handler';
import DraggableFlatList, {
    ScaleDecorator,
    RenderItemParams,
  } from "react-native-draggable-flatlist";
//import { createNativeWrapper } from 'react-native-gesture-handler';


function OverviewScreen({ navigation }){
    const auth = useAuth();
    const [keepConnectsArray, setKeepConnectsArray] = useState([{label: "No Keep Connects Registered", value: "null"}]);
    const [incompatibleKeepConnects, setIncompatibleKeepConnects] = useState([]);
    const [boolIncompatibleKeepConnects, setBoolIncompatibleKeepConnects] = useState(false);
    const [isSubscribed, setSubscription] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [selectedValue, setSelectedValue]= useState("");
    const [selectedName, setSelectedName]= useState();
    const getKeepConnectApi = useApi(keepConnectApi.getKeepConnectsWithFirmwareCheck);
    const getKeepConnectsFullData = useApi(keepConnectApi.getKeepConnectsFullData);
    const setDeviceOrder = useApi(keepConnectApi.setDeviceOrder);
    const triggerResetKeepConnectApi = useApi(keepConnectApi.triggersKeepConnectApi);
    const [defaultKeepConnect, setDefaultKeepConnect] = useState("");
    const [visibleTrigger, setVisibleTrigger] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("Error Communicating with Server");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [emptyKeepConnects, setEmptyKeepConnects] = useState(true);
    const [updateNeeded, setUpdateNeeded] = useState(false);
    const [keepConnectData, setKeepConnectData] = useState([]);
    const [momentNow, setMomentNow] = useState();



    useEffect(() => {
        loadKeepConnects();
    }, []);

    const loadKeepConnects = async () => {
        //setIsLoading(true);
        //console.log("async loading");
        setRefreshing(true);
        //const response = await getKeepConnectApi.request();
        const response = await getKeepConnectsFullData.request();
        //setKeepConnectData(response.data.data)
        //console.log(response);
        
        if (!response.ok){
            if (response.data && response.data.data && response.data.data.status && response.data.data.status == 403) return auth.logOut();
            setRequestFailed(true);
            setSubscription(true);
            setEmptyKeepConnects(true);
            setIsLoading(false);
            setRefreshing(false);
            return;
        } else setRequestFailed(false);
        if (response.data && response.data.hasOwnProperty('subscription')){ //subscription is only included in message if NOT subscribed
            setIsLoading(false);
            setRefreshing(false);
            setSubscription(false);
            return;
        }
        if (!response.data.data[0]){
            setSubscription(true);
            setEmptyKeepConnects(true);
            setIsLoading(false);
            setRefreshing(false);
            setKeepConnectData(response.data.data);
            setKeepConnectsArray([{label: "No Keep Connects Registered", value: "null"}]);            
            navigation.navigate(routes.ADDREMOVEKEEPCONNECTS, {changedDevices: setUpdateNeeded, initialSetup: true})
            return;
        }
        setSubscription(true);
        setEmptyKeepConnects(false);
        //console.log(response.data.settings);
       
        if(response.data.settings.license){
            await keepConnectStore.store("license", response.data.settings.license.replace(/\"/g, ""));
        } else{
            await keepConnectStore.store("license", "basic");
        }
        
        if(response.data.settings.kc_appTimezone){
            await keepConnectStore.store("kc_appTimezone", response.data.settings.kc_appTimezone);
        } else{
            await keepConnectStore.store("kc_appTimezone", "America/New_York");
        }
        //console.log(response.data.data);
        //console.log(response.data.deviceOrder);
        moment.tz.setDefault("America/New_York");
        setMomentNow(moment().tz("America/New_York"));

        if(response.data.deviceOrder[0] != ""){
            updateServerDeviceOrder(response.data.data, response.data.deviceOrder);
        } else{
            //console.log("No Device Order");
            setKeepConnectData(response.data.data);
        }
        setIsLoading(false);
        setRefreshing(false);

    }

    if (updateNeeded){
        if(Platform.OS === 'web') setIsLoading(true);
        setRefreshing(true);
        setUpdateNeeded(false);
        loadKeepConnects();
    }

    const getKeepConnectLabelByValue = (value) =>{
        for(var i = 0; i < keepConnectsArray.length; i++) {
            var obj = keepConnectsArray[i];
            if(obj.value === value){
                return obj.label;
            }
        }
    }

    const checkAndSetFirmwareCompatibility = (item) => {
        if (item.firmware.includes("KeepConnect")) return false;
        else return true;
    }

    const updateServerDeviceOrder = (data, deviceOrder) => {
        //console.log("setting order");
        let jsonObj = [];
        for (var i = 0; i < deviceOrder.length; i++){ 
            for (var y=0; y < data.length; y++){
                if(data[y].MAC == deviceOrder[i]){
                    jsonObj.push(data[y]);
                    break;
                }
            }
        }

        if(data.length != jsonObj.length){ //if orderMap is missing any devices, add those to the end.
            for (var z=0; z < data.length; z++){
                var isIncluded = false;
                for (var yz=0; yz < jsonObj.length; yz++){
                    if(data[z].MAC == jsonObj[yz].MAC){
                        isIncluded=true;
                        break;
                    }
                }
                if(!isIncluded) jsonObj.push(data[z]);
            }
        }
        setKeepConnectData(jsonObj);
    };

    const setNewServerOrder = async (data) => {
        setKeepConnectData(data);
        let temp = [];
        for (var x=0; x < data.length; x++){
            temp.push(data[x].MAC);
        }
        const response = await setDeviceOrder.request(temp);
        //console.log(response);

    };


    if(!isLoading){
        if(!selectedName){
            setSelectedName(getKeepConnectLabelByValue(keepConnectsArray[0].value));
        }
    }

    const renderItem = ({ item, drag, isActive }) => {
        moment.tz.setDefault("America/New_York");
        var then = moment(item.lastConnect, "YYYY-DD-MM HH:mm:ss");
        var isPowerPal = item.firmware.includes("PowerPal");
        const imageSource = isPowerPal ? require('../assets/ppIcon.png') : require('../assets/kcIcon.png');
        return (
          <ScaleDecorator>
            <TouchableOpacity
              onPress={()=>navigation.navigate(routes.DEVICE, {item: item, changedDevices: setUpdateNeeded})}
              onLongPress={drag}
            >
            <View style={isActive ? styles.item : styles.item2}>
            <View style={{paddingTop: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>{item.Name}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}>Status: {momentNow.diff(then, 'minutes') <= 7 ? <Text style={{color: '#33cc33'}}>Online</Text> : <Text style={{color: "red"}}>Offline</Text>}</Text></View></View>
            {!isPowerPal && <View style={{paddingTop: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Web ID: {item.WebID}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}>No. Resets: {item.resets}</Text></View></View>}
            {!isPowerPal && <View style={{paddingVertical: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Mode: {item.follower == "true" ? "Follower" : "Master"}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyle}></Text></View></View>}
            {isPowerPal && <View style={{paddingTop: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Web ID: {item.WebID}</Text></View><View style={{flex: 1}}><Text style={styles.TextStyleRightItem}>No. Events: {item.resets}</Text></View></View>}
            {isPowerPal && <View style={{paddingVertical: 2, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyle}>Power Pal</Text></View><View style={{flex: 1}}><Text style={styles.TextStyle}></Text></View></View>}
            <Image
                source={imageSource} // Adjust the path to your image file
                style={styles.imageStyle}
            />
            <ErrorMessage2
                error={"Firmware Update Required"}
                visible={!(item.firmware.includes("KeepConnect") || isPowerPal)}
                />
            </View>
            </TouchableOpacity>
          </ScaleDecorator>
        );
      };

    return(
        <Screen navigation={navigation} isLoading={isLoading} setUpdateNeeded={setUpdateNeeded} keepConnects={keepConnectData} showSettingsIcon={isSubscribed}>
        <Text></Text>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <React.Fragment>
        <View style={Platform.OS !== 'web' ? styles.buttonsContainer : styles.buttonsContainerWeb}>
        <ErrorMessage
        error={requestMessage}
        visible={requestFailed}
        />
        <ErrorMessage
        error={serverMessage}
        visible={errorFromServer}
        />
        {isSubscribed ? <React.Fragment>
        <View style={{paddingVertical: 20, flexDirection: "row"}}>
        <View style={{flex: 1}}></View><View style={{flex: 4}}><Text style={{fontWeight: "bold", alignSelf: "center"}}>Summary of Devices</Text>
        {Platform.OS === 'ios' ? <></> : <Text style={styles.TextStyleLink} onPress={ ()=> setUpdateNeeded(true) } >Refresh</Text>}
        </View>
        <View style={{flex: 1}}><Popable 
            backgroundColor="#4eb9d6"
            caret={false}
            content="If you have multiple Keep Connects, try holding item to enable drag / reorder">
            <Foundation name="lightbulb" size={32} color="#4eb9d6" />
        </Popable></View>
        </View>
        <View style={{minWidth:300, paddingBottom: 30}}>
        <DraggableFlatList
        data={keepConnectData}
        onDragEnd={({ data }) => {setNewServerOrder(data)}}
        keyExtractor={(item) => item.Name}
        renderItem={renderItem}
        refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={loadKeepConnects}
            />}
        />
        </View>
        </React.Fragment> :
        <React.Fragment>
        <Text></Text><Text></Text><Text></Text>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>No Active Service</Text>
        <Text>This User Account does not have active service. Please use Keep Connect website to manage your account.</Text>
        <Button
            title="Retry"
            onPress={()=>loadKeepConnects()}
        />
        <Button
            title="Log Out"
            onPress={()=>auth.logOut()}
        />
        </React.Fragment>}
        </View>
        </React.Fragment>}
        {(!isLoading && Platform.OS !== 'web') ? <View style={{paddingVertical: 10, flexDirection: "row"}}><View style={{flex: 1}}><Text style={styles.TextStyleFooter} onPress={ ()=> Linking.openURL('mailto: support@johnson-creative.com') } >Support</Text></View><View style={{flex: 1}}></View><View style={{flex: 1}}><Text style={styles.TextStyleFooter} onPress={ ()=> Linking.openURL('https://www.johnson-creative.com/keep-connect-cloud-services-mobile-app-privacy-policy/') } >Privacy</Text></View></View> : <></>}
    </Screen>
);
    }

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    buttonsContainer: {
        width: "100%",
        borderTopWidth :3,
        borderTopColor: colors.primary,
        borderBottomWidth :3,
        borderBottomColor: colors.primary,
        flex: .8,
        paddingBottom: 50,
    },
    buttonsContainerWeb: {
        width: "100%",
        borderTopWidth :3,
        borderTopColor: colors.primary,
        flex: .8,
        paddingBottom: 50,
    },
    logo: {
        width: 200,
        height: 200,
        resizeMode: 'contain',
    },
    logoContainer: {
        position: "absolute",
        top: -20,
        alignItems: "center",
        width: "100%",
    },
    tagline: {
        fontSize: 25,
        fontWeight: "600",
        paddingVertical: 20,
    },
    TextStyle: {
         color: "black",
         alignSelf: "flex-start",
         paddingLeft: 5,
      },
    TextStyleLink: {
        color: colors.primary,
        alignSelf: "center",
     },
    TextStyleFooter: {
         color: colors.primary,
         alignSelf: "center",
         paddingLeft: 20,
      },
    TextStyleRightItem: {
         color: "black",
         alignSelf: "flex-end",
         paddingRight: 5,
      },
    item: {
        borderWidth: 1,
        minWidth: 300,
        borderRadius: 10,
        borderColor: '#4eb9d6',
        padding: 5,
        marginVertical: 8,
        marginHorizontal: 16,
    },
    item2: {
        borderWidth: 1,
        minWidth: 300,
        borderRadius: 10,
        borderColor: '#4eb9d6',
        padding: 5,
        marginVertical: 8,
        marginHorizontal: 16,
    },
    title: {
        fontSize: 16,
    },
    imageStyle: {
        position: 'absolute',
        bottom: 0,
        right: 5,
        width: 80,
        height: 20,
    },
    });

export default OverviewScreen;