import React, { useEffect, useState } from 'react';
import { Text, View, Dimensions, ActivityIndicator, Platform, StyleSheet } from 'react-native';
//import DropDownPicker from 'react-native-dropdown-picker';
import Dropdown from '../components/Dropdown';
import { LineChart } from 'react-native-chart-kit';
import Modal from 'react-native-modal';
import moment from 'moment-timezone';

import Screen from '../components/Screen';
import Button from '../components/Button';
import routes from "../navigation/routes";
import { color } from 'react-native-reanimated';
import colors from '../config/colors';
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import useApi from "../hooks/useApi";
import KeepConnectChart from "../components/KeepConnectChart";
import { FlatList } from 'react-native-gesture-handler';

function DashboardScreenComprehensive({ route, navigation }){ 
    const [dates, setDates] = useState([]);
    const [dates2, setDates2] = useState([]);
    const [totalResets, setTotalResets] = useState([]);
    const [autoResetResets, setAutoResets] = useState([]);
    const [tcp_tls_failResets, setTcp_tls_fail] = useState([]);
    const [httpFailResets, setHttpFail] = useState([]);
    const [DNSfailResets, setDNSfail] = useState([]);
    const [unknownFailResets, setUnknownFail] = useState([]);
    const [WiFiFailResets, setWiFiFail]= useState([]);
    const [dhcp, setDHCP]= useState([]);
    const [isLoading, setIsloading]=useState(true);
    const [refreshing, setRefreshing ] = useState(false);
    const [updateNeeded, setUpdateNeeded] = useState(false);
    const [emptyResetData, setEmptyResetData] = useState(true);
    const [emptyDHCPData, setEmptyDHCPData] = useState(true);
    const [selectedValue, setSelectedValue] = useState(30);
    const [visibleTrigger, setVisibleTrigger] = useState(true);
    const getKeepConnectResetsApi = useApi(keepConnectApi.getResetDataComprehensive);
    const getKeepConnectDHCPApi = useApi(keepConnectApi.getDHCPData);
    const getKeepConnectResetsLogsApi = useApi(keepConnectApi.getResetDataLogsComprehensive);
    const getKeepConnectDHCPLogsApi = useApi(keepConnectApi.getDHCPDataLogs);
    const [resetLogs, setResetLogs] = useState([]);
    const [DHCPLogs, setDHCPLogs] = useState([]);
    const [viewCharts, setViewCharts] = useState(true);
    const [appTimezone, setAppTimeZone] = useState("America/New_York");
    const [macToNameMap, setMacToNameMap] = useState({});

    /*
    useEffect(() => {
        loadResetData(30);
    }, []);
    */

    let name = [];
    let total = [];
    let autoReset = [];
    let tcp_tls_fail = [];
    let httpFail = [];
    let DNSfail = [];
    let unknownFail = [];
    let WiFiFail = [];
    let nameDHCP = [];
    let dhcpChanges = [];

    const loadResetData = async (days) => {
        const keepConnectCode = getCodes(route.params.keepConnects);
        const response = await getKeepConnectResetsApi.request(keepConnectCode, days);  
        //console.log(response);
        //console.log(response.data[0]);      

        for (var i in response.data) {
            name.push(response.data[i].DateOnly);
            total.push(Number(response.data[i].total));
            autoReset.push(Number(response.data[i].Auto));
            tcp_tls_fail.push(Number(response.data[i].TCP_TLS));
            httpFail.push(Number(response.data[i].Http));
            DNSfail.push(Number(response.data[i].DNS));
            unknownFail.push(Number(response.data[i].Unknown));
            WiFiFail.push(Number(response.data[i].WiFi));
        };

        setDates(name);
        setTotalResets(total);
        setAutoResets(autoReset);
        setTcp_tls_fail(tcp_tls_fail);
        setHttpFail(httpFail);
        setDNSfail(DNSfail);
        setUnknownFail(unknownFail);
        setWiFiFail(WiFiFail);

        setIsloading(false);
        setRefreshing(false);

        if( !(name.length > 0) ) setEmptyResetData(true);
        else setEmptyResetData(false);
    }
    
    const loadResetLogs = async (days) => {
        const keepConnectCode = getCodes(route.params.keepConnects);
        const storedTimezone = await keepConnectStore.get("kc_appTimezone");
        const correctedStoredTimezone = (storedTimezone !== null ? storedTimezone.replace(/\"/g, "") : "America/New_York");
        setAppTimeZone(correctedStoredTimezone);
        moment.tz.setDefault("America/New_York");
        const response = await getKeepConnectResetsLogsApi.request(keepConnectCode, days);
        //console.log(route.params.keepConnects);
        var jsonObj = {};
        for (var i = 0; i < (route.params.keepConnects).length; i++){ 
            //console.log(route.params.keepConnects[i].MAC);
            jsonObj[route.params.keepConnects[i].MAC] = route.params.keepConnects[i].Name;
        }
        //console.log(jsonObj);
        setMacToNameMap(jsonObj);

        setResetLogs(response.data.reverse());

        //console.log(response);
        //console.log(response2);

        setIsloading(false);
        setRefreshing(false);
    }

    const getCodes = (data) => {
        var jsonObj2 = [];
        for (var i = 0; i < data.length; i++){ 
            jsonObj2.push(data[i].MAC.replace(/\"/g, ""));
        }
        //console.log(jsonObj);
        return jsonObj2;
    };

    const timeFrames = [
        {label: "30 Days", value: 30},
        {label: "60 Days", value: 60}, 
        {label: "90 Days", value: 90}, 
        {label: "180 Days", value: 180}, 
        {label: "All Time", value: 10000}, 
    ]

    const initializeSettings = async (charts) => {
        setVisibleTrigger(!visibleTrigger);
        setIsloading(true);
        setViewCharts(charts);
        if(charts){
            loadResetData(30);
        } else{
            loadResetLogs(30);
        }
      };

    
    const onChangeDropdown = (itemValue) => {
        setIsloading(true);
        setSelectedValue(itemValue);
        if(viewCharts) loadResetData(itemValue);
        else loadResetLogs(itemValue);
        }


    const renderResetItems = ({ item }) => {
        moment.tz.setDefault("America/New_York");
        var timestamp = moment.utc(item.time.replace(/\"/g, ""));

        return(
        <View style={{flexDirection: "row", borderBottomWidth: 1, borderColor: "#4eb9d6", alignItems:"center"}}>
        <Text>{timestamp.tz(appTimezone).format('YYYY-MM-DD HH:mm:ss')} </Text><View style={{flexDirection: "column"}}><Text style={{fontWeight: "bold"}}>{macToNameMap[item.userMac]}</Text><Text>Reason : {item.reason}</Text></View>
        </View>);
        };


    if (updateNeeded){
        if(Platform.OS === 'web') setIsloading(true);
        setRefreshing(true);
        setUpdateNeeded(false);
        if(viewCharts) loadResetData(selectedValue);
        else loadResetLogs(selectedValue);
    }
        
        return(
        <Screen isLoading={refreshing} setUpdateNeeded={setUpdateNeeded}>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <View>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Reset Data For All Units:</Text>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>For the past:</Text>
        <Dropdown
            itemsArray={timeFrames}
            defaultValueProp={selectedValue}
            containerStyle={{height: 40}}
            style={{backgroundColor: '#fafafa'}}
            itemStyle={{
                justifyContent: 'center'
            }}
            labelStyle={{textAlign: 'center'}}
            dropDownStyle={{backgroundColor: '#fafafa'}}
            onChangeItemProp={ item => onChangeDropdown(item)}
        /><Text></Text><Text></Text>
        {Platform.OS === 'ios' ? <></> : <Text style={styles.TextStyle} onPress={ ()=> setUpdateNeeded(true) } >Refresh</Text>}
        {viewCharts ?
                <React.Fragment>
                {emptyResetData ? <><Text></Text><Text style={{fontWeight: "bold", alignSelf: "center"}}>No Reset Data Exists in the last {selectedValue} days</Text><Text></Text></> : <React.Fragment></React.Fragment>}
                {!emptyResetData ? <KeepConnectChart label={dates} data={totalResets} text={"Total Events"} /> : <></>}
                {!emptyResetData ? <KeepConnectChart label={dates} data={autoResetResets} text={"Auto Resets"} /> : <></>}
                {!emptyResetData ? <KeepConnectChart label={dates} data={tcp_tls_failResets} text={"TCP / TLS Failure Resets"} /> : <></>}
                {!emptyResetData ? <KeepConnectChart label={dates} data={httpFailResets} text={ "HTTP Failure Resets"} /> : <></>}
                {!emptyResetData ? <KeepConnectChart label={dates} data={DNSfailResets} text={"DNS Failure Resets"} /> : <></>}
                {!emptyResetData ? <KeepConnectChart label={dates} data={WiFiFailResets} text={"WiFi Failure Resets"} /> : <></>}
                {!emptyResetData ? <KeepConnectChart label={dates} data={unknownFailResets} text={"Other Events / Resets"} />  : <></>}
                </React.Fragment> :
                <React.Fragment>
                <Text style={{fontWeight: "bold", alignSelf: "center"}}>Event Data Log:</Text>
                <Text style={{alignSelf: "center"}}>Timezone: ({moment().tz(appTimezone).format('z')}) {'\n'}</Text>
                {resetLogs.length !== 0 ?
                <FlatList data={resetLogs} renderItem={renderResetItems} keyExtractor={item => item.time+item.userMac} />
                : <><Text></Text><Text style={{alignSelf: "center"}}>No data for this range</Text></>
                }
                </React.Fragment>
        }
        </View>
        }
        <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"} onBackdropPress={()=>navigation.goBack()}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Please select:{'\n'}</Text>
            <Button title="Charts" onPress={()=>initializeSettings(true)} />
            <Button title="Logs" onPress={()=>initializeSettings(false)} />
            </View>
            </View>
        </Modal>
    </Screen>
);
    }

const styles = StyleSheet.create({
    TextStyle: {
            color: colors.primary,
            alignSelf: "center",
        },
    });

export default DashboardScreenComprehensive;