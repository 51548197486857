import apiClient from './client';


const getKeepConnects = () => apiClient.get('/mobileapi/v1/getkeepconnects', {timestamp: Date.now()}); //timestamp added to ensure get request is unique and would therefore bypass any caching enforced by servers down the road.
const getKeepConnectsWithFirmwareCheck = () => apiClient.get('/mobileapi/v1/getkeepconnectswithfirmwarecheck', {timestamp: Date.now()});
const getKeepConnectsFullData = () => apiClient.get('/mobileapi/v1/getkeepconnectsfulldata', {timestamp: Date.now()});
const getCloudKeepConnects = () => apiClient.get('/mobileapi/v1/getCloudKeepConnects', {timestamp: Date.now()});
const getAppSettings = () => apiClient.get('/mobileapi/v1/getAppSettings', {timestamp: Date.now()});
const setAppSettings = (appSettings) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/setAppSettings', {appSettings: appSettings});
};
const setDeviceOrder = (data) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/setKeepConnectOrder', {deviceOrder: data});
};
const getCustomMsgs = (keepConnectCode) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/getCustomMsgs', {userMac: keepConnectCode});
};
const setCustomMsgs = (postData) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/setCustomMsgs', postData);
};
const getResetData = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetResetData', {userMac: keepConnectCode, range: days});
};
const getResetDataComprehensive = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetResetDataComprehensive', {userMac: keepConnectCode, range: days});
};
const getDHCPData = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetDHCPData2', {userMac: keepConnectCode, range: days});
};
const getResetDataLogs = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetResetDataLogs', {userMac: keepConnectCode, range: days});
};
const getResetDataLogsComprehensive = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetResetDataLogsComprehensive', {userMac: keepConnectCode, range: days});
};
const getDHCPDataLogs = (keepConnectCode, days) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetDHCPDataLogs', {userMac: keepConnectCode, range: days});
};
const registerKeepConnect = (webID, code, name) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/addkeepconnects', {keepConnectWebID: webID , keepConnectCode:code, keepConnectName:name});
};
const removeKeepConnect = (code) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/removekeepconnects', {keepConnects:code});
};
const subscribeCloudKeepConnect = (code, delay) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/addcloudkeepconnects', {offlineDelay: delay, keepConnects:code});
};
const removeCloudKeepConnect = (code) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/removecloudkeepconnects', {keepConnects:code});
};
const getKeepConnectSettings = (code) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetSettings', {userMac:code});
};
const getPipelines = (code) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiGetPipelines', {userMac:code});
};
const clearLogs = (code) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiClearLogs', {userMac:code});
};
const triggersKeepConnectApi = (postData) => {
    //console.log("userMac: " + keepConnectCode);
    return apiClient.post('/mobileapi/v1/apiTriggers2', postData);
};

export default {
    getKeepConnects,
    getKeepConnectsFullData,
    getKeepConnectsWithFirmwareCheck,
    getResetData,
    getResetDataComprehensive,
    getDHCPData,
    getResetDataLogs,
    getResetDataLogsComprehensive,
    getDHCPDataLogs,
    registerKeepConnect,
    removeKeepConnect,
    getKeepConnectSettings,
    getPipelines,
    getCloudKeepConnects,
    subscribeCloudKeepConnect,
    removeCloudKeepConnect,
    triggersKeepConnectApi,
    getAppSettings,
    setAppSettings,
    getCustomMsgs,
    setCustomMsgs,
    clearLogs,
    setDeviceOrder,
}