import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator, Platform, Switch, TextInput, TouchableOpacity } from "react-native";
import { AntDesign } from '@expo/vector-icons';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import Modal from 'react-native-modal';
import { Buffer } from 'buffer';
import alert from '../components/alert';
import URLCheckFrameComponent from '../components/pipelines/URLCheckFrameComponent';
import PingFrameComponent from '../components/pipelines/PingFrameComponent';
import PowerCycleFrameComponent from "../components/pipelines/PowerCycleFrameComponent";
import WiFiCheckFrameComponent from "../components/pipelines/WiFiCheckFrameComponent";
import KCRoundtripFrameComponent from "../components/pipelines/KCRoundtripFrameComponent";
import SpeedTestFrameComponent from "../components/pipelines/SpeedTestFrameComponent";
import { FrameType } from '../components/pipelines/frameType.js';
import WebView from "react-native-webview";
import HTML from "react-native-render-html";
import moment from 'moment-timezone';


import Screen from '../components/Screen';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import colors from "../config/colors";

const PipelineParamsEditor = ({ pipelineParams, setPipelineParams }) => (
    <View style={styles.pipeline}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text>Pipeline Name:</Text>
        <TextInput
          style={styles.input}
          value={pipelineParams.pipelineName}
          onChangeText={(text) =>
            setPipelineParams({ ...pipelineParams, pipelineName: text })
          }
        />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text>Run Interval:{'\n'}(Seconds)</Text>
        <TextInput
          style={styles.input}
          value={isNaN(pipelineParams.delayBeforeStart) ? '' : pipelineParams.delayBeforeStart.toString()}
          onChangeText={(text) =>
            setPipelineParams({
              ...pipelineParams,
              delayBeforeStart: parseInt(text),
            })
          }
          keyboardType="numeric"
        />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text>Stack Size:{'\n'}(kB)</Text>
        <TextInput
          style={styles.input}
          value={isNaN(pipelineParams.kbStackSize) ? '' : pipelineParams.kbStackSize.toString()}
          onChangeText={(text) =>
            setPipelineParams({
              ...pipelineParams,
              kbStackSize: Math.min(255, Math.max(0, parseInt(text))),
            })
          }
          keyboardType="numeric"
        />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text>Block on VPN{'\n'}Activity:</Text>
        <Switch
          value={pipelineParams.blockOnVpnActivity}
          onValueChange={(value) => {
            setPipelineParams({
              ...pipelineParams,
              blockOnVpnActivity: value
            });
          }}
        />
      </View>
    </View>
  );
  
  // Define a mapping from frame type to component
  const frameTypeToComponent = {
    [FrameType.URL_CHECK_FRAME]: URLCheckFrameComponent, 
    [FrameType.PING_FRAME]: PingFrameComponent,
    //[FrameType.JITTER_FRAME]: JitterFrameComponent,
    [FrameType.SPEEDTEST_FRAME]: SpeedTestFrameComponent,
    [FrameType.WIFI_CHECK_FRAME]: WiFiCheckFrameComponent,
    [FrameType.KC_ROUNDTRIP_FRAME]: KCRoundtripFrameComponent,
    //[FrameType.DEVICE_REBOOT_FRAME]: DeviceRebootFrameComponent,
    //[FrameType.ONGOING_PING_COUNTER_FRAME]: OngoingPingCounterFrameComponent,
    //[FrameType.API_MESSAGE_ACTION_FRAME]: APIMessageActionFrameComponent,
    [FrameType.POWER_CYCLE_FRAME]: PowerCycleFrameComponent,
  };
  // Define a mapping from frame type to component
  const frameTypeToName = {
    [FrameType.URL_CHECK_FRAME]: "URL Check Frame",
    [FrameType.PING_FRAME]: "Ping Check Frame",
    [FrameType.JITTER_FRAME]: "Jitter Frame",
    [FrameType.SPEEDTEST_FRAME]: "Speed Test Frame",
    [FrameType.WIFI_CHECK_FRAME]: "WiFi Check Frame",
    [FrameType.KC_ROUNDTRIP_FRAME]: "KC RoundTrip Frames ",
    [FrameType.DEVICE_REBOOT_FRAME]: "Device Reboot Frame",
    [FrameType.ONGOING_PING_COUNTER_FRAME]: "Ongoing Ping Counter Frame",
    [FrameType.API_MESSAGE_ACTION_FRAME]: "API MessageAction Frame",
    [FrameType.POWER_CYCLE_FRAME]: "Power Cycle Frame",
  };

function PipelinesScreen({ route, navigation }){
    const getKeepConnectPipelinesApi = useApi(keepConnectApi.getPipelines); 
    const [isLoading, setIsLoading ] = useState(true);
    const [refreshing, setRefreshing ] = useState(false);
    const [updateNeeded, setUpdateNeeded] = useState(false);   
    const [currentPipelineIndex, setCurrentPipelineIndex] = useState(0);
    const [newFrameType, setNewFrameType] = useState(FrameType.URL_CHECK_FRAME);
    const [isAddFrameModalVisible, setAddFrameModalVisible] = useState(false);
    // Add a piece of state to keep track of the position where the new frame should be added
    const [addFramePosition, setAddFramePosition] = useState(0);

    const [pipelines, setPipelines] = useState([{
        pipelineName: 'Default Pipeline',
        pipelineID: 0,
        version: 1,
        delayBeforeStart: 300,
        kbStackSize: 8,
        blockOnVpnActivity: true,
        frames: [],
      }]);
  
    const frameTypes = Object.values(FrameType);

    useEffect(() => {
        const loadSettings = async () => {
          try {
            let bytesBase64;
            let commaSeparatedPipelinesNames;
            console.log(route.params);
    
            if (route.params.customPipelines && route.params.pipelinesNames) {
              console.log("Passed Parameters");  
              console.log(route.params.customPipelines);
                // Use the props passed from the parent component
                // You might need to decode the customPipelines from base64 and split the pipelinesNames by comma
                bytesBase64 = route.params.customPipelines;
                commaSeparatedPipelinesNames = route.params.pipelinesNames;
            } else {
                // Load the data from the database
                console.log("loading from api");
                const keepConnectCode = await keepConnectStore.get("keepConnect");
                const response = await getKeepConnectPipelinesApi.request(keepConnectCode);
                console.log(response);
                bytesBase64 = response.data[0].pipelines;
                commaSeparatedPipelinesNames = response.data[0].pipelinesNames;
            }
            //const keepConnectCode = await keepConnectStore.get("keepConnect");
            //const response = await getKeepConnectPipelinesApi.request(keepConnectCode);
            //const bytesBase64 = response.data[0].pipelines;
            //const commaSeparatedPipelinesNames = response.data[0].pipelinesNames;
        
            // decode the Base64 pipelines into bytes
            const bytes = Buffer.from(bytesBase64, 'base64');
            console.log("Decoded Bytes: ", bytes);
            
            // Split the pipeline names by comma
            console.log("make it here");
            console.log(commaSeparatedPipelinesNames);
            const pipelineNames = commaSeparatedPipelinesNames.split(',');
            
            let offset = 0;
            const newPipelines = [];
            
            // Read the pipelinesSize
            const pipelinesSize = bytes.readUInt16LE(offset);
            console.log("Size: ", pipelinesSize);
            offset += 2; // Equivalent to "address += sizeof(pipelinesSize);" in C++
            
            const numberOfPipelines = bytes[offset++];
            for (let i = 0; i < numberOfPipelines; ++i) {
              const newPipeline = {
                pipelineID: bytes[offset++], // Read pipelineID
                version: bytes[offset++], // Read version
                delayBeforeStart: bytes.readInt32LE(offset)/1000, // Read delayBeforeStart
                kbStackSize: bytes[offset+4], // Read stack size
                blockOnVpnActivity: bytes[offset+5] == 1, //Read block on VPN activity
                frames: [],
              };
              console.log(newPipeline);
              offset += 6 //since it couldn't be incremented in the object literal above
              
              // Read numberOfFrames
              const numberOfFrames = bytes[offset++];
              console.log("Number of frames read: ", numberOfFrames);
              offset += 5; // Skip the 5 spare bytes
        
              for (let j = 0; j < numberOfFrames; ++j) {
                console.log("frameType Offset in Reading: ", offset);
                const frameType = bytes[offset++];
                console.log("frameType Number: ", frameType);
                console.log("frameType Number: ", bytes[16]);
                const FrameComponent = frameTypeToComponent[frameType];
                console.log("FrameComponent: ", FrameComponent);
                const { frameCore, bytesRead } = FrameComponent.fromBytes(bytes, offset);
                console.log("framesize read: ", bytesRead);
                offset += bytesRead;
                newPipeline.frames.push(frameCore);
              }
        
              newPipeline.pipelineName = pipelineNames[i]; // Set the pipeline name
              newPipelines.push(newPipeline);
            }
            console.log("Actual Size: ", offset);
            // Check the size of the pipelines
            if (offset !== pipelinesSize) {
              console.log("Pipelines Size Mismatch");
              throw new Error(`Size mismatch: expected ${pipelinesSize}, got ${offset}`);
            }
            console.log(newPipelines);
            console.log(newPipelines[0].frames[0]);
            setPipelines(newPipelines);
            setIsLoading(false);
            if(newPipelines.length ===1 && newPipelines[0].frames.length === 0) setAddFrameModalVisible(true);
          } catch (e) {
            console.log("Error in Reading", e);
            // If an error occurs (for example, the byte array is not available), create a default Pipeline
            const newPipeline = {
              pipelineName: 'Default Pipeline', // default pipeline name
              pipelineID: 0, // default pipelineID
              version: 1, // default version
              delayBeforeStart: 300, // default delayBeforeStart
              kbStackSize: 8, // default kbStackSize
              blockOnVpnActivity: true, //default 
              frames: [], // empty frames array
            };
            setPipelines([newPipeline]);
            setIsLoading(false);
            setAddFrameModalVisible(true);
          }
        };
        
        loadSettings();

    }, []);
        
      
      
    const addPipeline = () => {
      const newPipeline = {
        pipelineName: 'Pipeline ' + (pipelines.length + 1), // default pipeline name
        pipelineID: 0, // default pipelineID
        version: 1, // default version
        delayBeforeStart: 300, // default delayBeforeStart
        kbStackSize: 8, // default kbStackSize
        blockOnVpnActivity: true,
        frames: [], // empty frames array
      };
      setPipelines([...pipelines, newPipeline]);
      setCurrentPipelineIndex(pipelines.length); // Select the new pipeline
    };

    const deleteCurrentPipeline = () => {
      if (pipelines.length > 1) {
        const updatedPipelines = pipelines.filter((_, index) => index !== currentPipelineIndex);
        setPipelines(updatedPipelines);
        setCurrentPipelineIndex(0); // Select the first pipeline
      } else {
        if (pipelines[0].frames.length > 0) {
          // Clear all the frames out
          const updatedPipelines = [{ ...pipelines[0], frames: [] }];
          setPipelines(updatedPipelines);
        }
        alert('You cannot delete the last pipeline.');
      }
    };
  
    const addFrameCore = (position) => {
      const FrameComponent = frameTypeToComponent[newFrameType];
      const newFrameCores = FrameComponent.newFrameCore();
    
      // Ensure newFrameCores is always an array
      const frameCoresArray = Array.isArray(newFrameCores) ? newFrameCores : [newFrameCores];
    
      const updatedPipelines = [...pipelines];
      updatedPipelines[currentPipelineIndex].frames.splice(position, 0, ...frameCoresArray);
      setPipelines(updatedPipelines);
    };
    
  
    const updateFrameCore = (index, updatedForm) => {
      const updatedPipelines = [...pipelines];
      updatedPipelines[currentPipelineIndex].frames[index] = updatedForm;
      setPipelines(updatedPipelines);
    };
  
    const addFrameCoreAfter = (index) => {
      addFrameCore(index + 1);
    };

    const addFrameCoreAtEnd = () => {
      const frameCount = pipelines[currentPipelineIndex].frames.length;
      addFrameCore(frameCount);
    };
  
    const removeFrameCore = (indexToRemove) => {
      const updatedPipelines = [...pipelines];
      updatedPipelines[currentPipelineIndex].frames = updatedPipelines[
        currentPipelineIndex
      ].frames.filter((_, index) => index !== indexToRemove);
      setPipelines(updatedPipelines);
    };

    const moveFrameUp = (index) => {
      if (index > 0) { // Ensure the frame is not the first one
        const updatedPipelines = [...pipelines];
        const { frames } = updatedPipelines[currentPipelineIndex];
    
        // Swap the frames at positions index and index - 1
        [frames[index - 1], frames[index]] = [frames[index], frames[index - 1]];
        
        setPipelines(updatedPipelines);
      }
    };
    
    const moveFrameDown = (index) => {
      const updatedPipelines = [...pipelines];
      const { frames } = updatedPipelines[currentPipelineIndex];
    
      if (index < frames.length - 1) { // Ensure the frame is not the last one
        // Swap the frames at positions index and index + 1
        [frames[index + 1], frames[index]] = [frames[index], frames[index + 1]];
    
        setPipelines(updatedPipelines);
      }
    };
    
  
    const submit = () => {
      const allPipelinesBytes = [[pipelines.length]];
      
      for (const pipeline of pipelines) {
        const pipelineBytes = [];
    
        // Add the pipeline's properties to pipelineBytes
        pipelineBytes.push(pipeline.pipelineID);
        pipelineBytes.push(pipeline.version);
    
        // Add delayBeforeStart as a 4-byte integer
        const delayBeforeStartBuffer = Buffer.alloc(4);
        delayBeforeStartBuffer.writeInt32LE(pipeline.delayBeforeStart*1000);
        pipelineBytes.push(...delayBeforeStartBuffer);
    
        pipelineBytes.push(pipeline.kbStackSize);

        pipelineBytes.push(pipeline.blockOnVpnActivity ? 1 : 0);
    
        // Add the number of frames in the pipeline to pipelineBytes
        pipelineBytes.push(pipeline.frames.length);
    
        // Skip the spare bytes
        pipelineBytes.push(0, 0, 0, 0, 0);
        console.log("PipelineBytes: ", (pipelineBytes.length + 2 + allPipelinesBytes.length));
    
        for (const frame of pipeline.frames) {
          console.log("frame.frameType: ", frame.frameType); 
          const FrameComponent = frameTypeToComponent[frame.frameType];
          console.log("Writing Frame: ", FrameComponent);
          const frameBytes = FrameComponent.toBytes(frame);
          console.log("FrameBytes Written: ", frameBytes.length)
          pipelineBytes.push(...frameBytes);
        }
    
        allPipelinesBytes.push(pipelineBytes);
      }
    
      // Calculate the total size of allPipelinesBytes
      const totalSize = allPipelinesBytes.reduce((sum, bytes) => sum + bytes.length, 0);
      console.log("Total Size calc: ", totalSize);
    
      // Create a buffer for the size
      const sizeBuffer = Buffer.alloc(2);
      sizeBuffer.writeUInt16LE(totalSize + 2);
    
      // Add sizeBuffer to the beginning of allPipelinesBytes
      allPipelinesBytes.unshift([...sizeBuffer]);

      console.log(allPipelinesBytes);
    
      // Convert allPipelinesBytes to a base64 string
      // Flatten allPipelinesBytes into one single byte array
      const flatPipelinesBytes = allPipelinesBytes.flat(Infinity);

      // Convert the flat array into a Buffer
      const pipelinesBuffer = Buffer.from(flatPipelinesBytes);

      // Convert the Buffer into a Base64 string
      const allPipelinesString = pipelinesBuffer.toString('base64');
    
      // Now allPipelinesBase64 is an array of base64 strings, where each string is the base64 representation of a pipeline.
      // You can now send this data to your backend, save it to storage, etc.
    
      // For example, to print it:
      route.params.setCustomPipelines(allPipelinesString);
      route.params.setPipelinesNames(pipelines.map(p => p.pipelineName).join(","));
      //console.log(allPipelinesBase64);
      alert(
        "Success!",
        "You still need to send these settings to your device. You'll now return to the Change Settings screen where that can be done.",
        [
          { text: "OK", onPress: () => {} }
        ]
      );
      navigation.goBack();
    };
      
    
    return(
    <Screen isLoading={refreshing} setUpdateNeeded={setUpdateNeeded}>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <React.Fragment>
            <View style={{
              backgroundColor: "#E8E8E8",
              paddingVertical: 5,
              paddingHorizontal: 10,
              borderRadius: 20,
            }}>
            <Dropdown
              labelText='Select Pipeline'
              defaultValueProp={currentPipelineIndex}
              itemsArray={pipelines.map((pipeline, index) => ({
                label: pipeline.pipelineName,
                value: index,
              }))}
              onChangeItemProp={setCurrentPipelineIndex}
              />
            <Button title="Delete Current Pipeline" onPress={deleteCurrentPipeline} />
            <Button title="Create New Pipeline" onPress={addPipeline} />
            <PipelineParamsEditor 
                pipelineParams={pipelines[currentPipelineIndex]} 
                setPipelineParams={(updatedParams) => {
                  const updatedPipelines = [...pipelines];
                  updatedPipelines[currentPipelineIndex] = updatedParams;
                  setPipelines(updatedPipelines);
                }}
                />
            </View>


        <View style={{borderBottomColor: colors.primary, borderBottomWidth: 4, marginVertical: 10}}/>
            {pipelines[currentPipelineIndex]?.frames.map((frameCore, index) => {
              const FrameComponent = frameTypeToComponent[frameCore.frameType];
              return (
                <>
                <View key={index} style={styles.frameCore}>
                    <FrameComponent
                    index={index}
                    frameCore={frameCore}
                    updateFrameCore={(updatedForm) => updateFrameCore(index, updatedForm)}
                    />

                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingVertical: 10,
                      paddingHorizontal: 40,
                    }}>
                    <TouchableOpacity onPress={() => removeFrameCore(index)}>
                        <AntDesign name="delete" size={28} color={colors.primary} />
                    </TouchableOpacity>
                    {index > 0 && ( // Only show "up" button if it's not the first frame
                      <TouchableOpacity onPress={() => moveFrameUp(index)}>
                        <AntDesign name="arrowup" size={28} color={colors.primary} />
                      </TouchableOpacity>
                    )}

                    {index < pipelines[currentPipelineIndex].frames.length - 1 && ( // Only show "down" button if it's not the last frame
                      <TouchableOpacity onPress={() => moveFrameDown(index)}>
                        <AntDesign name="arrowdown" size={28} color={colors.primary} />
                      </TouchableOpacity>
                    )}
                    <TouchableOpacity onPress={() => {
                        setAddFramePosition(index + 1);
                        setAddFrameModalVisible(true);
                      }}>
                        <AntDesign name="plus" size={28} color={colors.primary} />
                    </TouchableOpacity>
                  </View>
                </View></>
                );
              })}

              <View style={{
                backgroundColor: "#E8E8E8",
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRadius: 20,
              }}>
                  <Dropdown
                    labelText='Select Frame Type'
                    defaultValueProp={newFrameType}
                    itemsArray={frameTypes.map((frameType) => ({
                      label: frameTypeToName[frameType],
                      value: frameType,
                    }))}
                    onChangeItemProp={setNewFrameType}
                  />
                  <Button title="Add Frame" onPress={addFrameCoreAtEnd} />
                  <Button title="Add frame at beginning" onPress={() => addFrameCore(0)} />
              </View>
            <Button title="Save and Return" onPress={submit} />
            <Button title="Cancel and Return" onPress={() => navigation.goBack()} />
            <Modal isVisible={isAddFrameModalVisible} transparent={true} animationType = {"slide"} onBackdropPress={() => setAddFrameModalVisible(false)}>
              <View style={{height: 300,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
                <View style={{width:300}}>
                  <Text style={{alignSelf: "center"}}>Select the frame type and click "Add Frame"{'\n'}</Text>
                  
                  <Dropdown
                    labelText='Select Frame Type'
                    defaultValueProp={newFrameType}
                    itemsArray={frameTypes.map((frameType) => ({
                      label: frameTypeToName[frameType],
                      value: frameType,
                    }))}
                    onChangeItemProp={setNewFrameType}
                  />
                  <Button title="Add Frame" onPress={() => {
                    addFrameCore(addFramePosition);
                    setAddFrameModalVisible(false);
                  }} />
                </View>
              </View>
            </Modal>
      </React.Fragment>}
    </Screen>
);
    }

const styles = StyleSheet.create({
    TextStyle: {
            color: colors.primary,
            alignSelf: "center",
        },
    picker: {
            height: 50,
            width: '75%',
            alignSelf: 'center',  // Center the picker
            },
            frameCore: {
            borderWidth: 1,
            borderRadius: 10,
            borderColor: colors.primary,
            marginVertical: 10,
            padding: 0,
            },
            pipeline: {
            borderWidth: 1,
            borderRadius: 10,
            borderColor: colors.primary,
            backgroundColor: "white",
            margin: 10,
            padding: 10,
            },
            input: {
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 4,
            padding: 5,
            marginBottom: 10,
            width: '50%', // use the full width
            },
            inputWide: {
            borderWidth: 1,
            borderColor: colors.primary,
            borderRadius: 4,
            padding: 5,
            marginBottom: 10,
            width: '100%', // use the full width
            },
    });

export default PipelinesScreen;