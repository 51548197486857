import React from "react";
import { View, Text, Platform } from "react-native";
import DropDownPicker from 'react-native-dropdown-picker';
import { Dropdown } from 'react-native-element-dropdown';
import colors from "../config/colors";

function Dropdown1({ labelText, defaultValueProp, itemsArray, onChangeItemProp, onTop, disabled = false }) {
  const isAndroid = Platform.OS === 'android';

  //console.log('itemsArray:', itemsArray)


  return (
    <>
      {labelText && (
        <><Text></Text><Text style={{ alignSelf: "center" }}>{labelText}</Text></>
      )}
      {isAndroid ? (
        <View>
          <Dropdown
              data={itemsArray}
              value={defaultValueProp}
              labelField="label"
              valueField="value"
              searchField="label"
              onChange={(item) => onChangeItemProp(item.value)}
              style={{ backgroundColor: '#fafafa', height: 40 }}
              disable={disabled}
          />
        </View>
      ) : (
        <View style={{ zIndex: onTop ? 6000 : 5000, elevation: onTop ? 6000 : 5000 }}>
          <DropDownPicker
            items={itemsArray}
            defaultValue={defaultValueProp}
            containerStyle={{ height: 40 }}
            style={{ backgroundColor: '#fafafa' }}
            itemStyle={{
              justifyContent: 'flex-start'
            }}
            dropDownStyle={{ backgroundColor: '#fafafa' }}
            onChangeItem={item => onChangeItemProp(item.value)}
            disabled={disabled}
          />
        </View>
      )}
    </>
  );
}

export default Dropdown1;
